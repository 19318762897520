/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import {
  IonButton,
  IonCol,
  IonGrid,
  IonModal,
  IonRow,
  IonText,
} from '@ionic/react'
import { trashBinOutline } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ConfirmModal, ValidatedIcon } from 'src/components'
import './ConfirmModal.css'

export interface IConfirmModalProps {
  isOpen: boolean
  canEdit: boolean
  title?: string
  author?: string
  daty?: string
  text?: string
  okText?: string
  cancelText?: string
  icon?: any
  id: string
  onCancel: () => void
  onConfirm: (value?: string) => void
}

const ModalTransmission: React.FC<IConfirmModalProps> = ({
  isOpen,
  text,
  title,
  okText,
  cancelText,
  canEdit,
  author,
  daty,
  id,
  onCancel,
  icon,
  onConfirm,
}) => {
  const history = useHistory()
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(isOpen)
  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen, setShowModal])
  const handleCancel = (): void => {
    setShowModal(false)
    onCancel()
  }

  const initialModalBreakPoint = (iscanEdit: boolean, nbRe: number): number => {
    if (iscanEdit && nbRe <= 25) {
      return 0.5
    }

    if (!iscanEdit && nbRe <= 25) {
      return 0.33
    }

    if (!iscanEdit && nbRe <= 1050) {
      return 0.6
    }
    if (iscanEdit && nbRe <= 1050) {
      return 0.7
    }
    return 1
  }
  const confirmActions = {
    onCancel: () => setOpenConfirmation(false),
    onConfirm: async () => {
      setOpenConfirmation(false)
      onConfirm(id)
    },
  }

  return (
    <>
      <ConfirmModal
        isOpen={openConfirmation}
        icon={<ValidatedIcon icon={trashBinOutline} warningColor height={50} />}
        title="Êtes-vous sûr de vouloir supprimer?"
        okText="Confirmer"
        onConfirm={confirmActions.onConfirm}
        onCancel={confirmActions.onCancel}
      />
      <IonModal
        className="note-modal"
        mode="ios"
        isOpen={showModal}
        initialBreakpoint={initialModalBreakPoint(canEdit, text?.length || 25)}
        onDidDismiss={() => handleCancel()}
        breakpoints={[0, 0.33, 0.66, 0.8, 1]}
      >
        <IonGrid className="ion-padding note-wrap">
          <IonRow>
            <IonCol size="12" className="ion-no-padding">
              {icon}
              {title && (
                <IonText>
                  <h4>{title}</h4>
                </IonText>
              )}
              {text && (
                <IonText>
                  <p className="note-desc">{text}</p>
                </IonText>
              )}
            </IonCol>
            <IonCol size="12" className="ion-no-padding ion-text-center">
              <IonText className="note-date">
                {daty?.includes('hier') || daty?.includes('Aujour') ? '' : 'du'}{' '}
                {daty}
              </IonText>
            </IonCol>
            <IonCol
              size="12"
              className="ion-no-padding ion-text-center ion-margin-bottom"
            >
              <IonText className="note-by">
                par {canEdit ? 'Moi' : author}
              </IonText>
            </IonCol>
          </IonRow>
          {canEdit && (
            <>
              <IonRow>
                <IonCol>
                  <IonButton
                    color="primary"
                    expand="block"
                    size="large"
                    fill="outline"
                    onClick={() => {
                      history.replace(`/udpdate/transmissions/${id}`)
                      setShowModal(false)
                    }}
                  >
                    Editer
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-no-padding">
                  <IonButton
                    color="primary"
                    expand="block"
                    size="large"
                    onClick={() => setOpenConfirmation(true)}
                  >
                    Supprimer
                  </IonButton>
                </IonCol>
              </IonRow>
            </>
          )}
          <IonRow>
            <IonCol className="ion-no-padding">
              <IonButton
                className="btn-cancel"
                fill="clear"
                color="tertiary"
                expand="block"
                onClick={() => handleCancel()}
              >
                Fermer
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
    </>
  )
}

export default ModalTransmission
