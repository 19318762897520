/* eslint-disable no-console */
/* eslint-disable camelcase */
import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonModal,
  IonRow,
  IonText,
  useIonLoading,
  useIonToast,
} from '@ionic/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UsagerListItem from 'src/components/UsagerListItem/UsagerListItem'
import { CREATE_ACTION_MUTATION } from 'src/graphql/actions-types.graphql'
import { UsagerEntity } from 'src/models/gql/graphql'
import {
  removeStepThree,
  switchNbUnkown,
} from 'src/stores/slices/ficherActionsSlices'
import { RootState } from 'src/stores/store'

import './ConfirmModal.css'

export interface IConfirmModalProps {
  isOpen: boolean
  maxi: number
  backdropDismiss?: boolean
  onCancel: () => void
}
const ModalUsagerSelectedEdit: React.FC<IConfirmModalProps> = ({
  isOpen,
  maxi,
  backdropDismiss,
  onCancel,
}) => {
  const modal = useRef<HTMLIonModalElement>(null)

  const [presentLoading, dismissLoading] = useIonLoading()
  const [showModal, setShowModal] = useState(isOpen)
  const dispatch = useDispatch()
  const [
    createAction,
    { loading: createLoading, error: errorCreate },
  ] = useMutation(CREATE_ACTION_MUTATION)
  const {
    step_three,
    currentStep,
    nbUnkown,
    nbUnknownUsagers,
    step_one,
    step_two,
  } = useSelector((state: RootState) => state.ficheActions)

  useEffect(() => {
    console.log('isOPen', isOpen)
    setShowModal(isOpen)
  }, [isOpen, setShowModal])

  useEffect(() => {
    if (createLoading) {
      presentLoading()
    } else {
      dismissLoading()
    }

    return () => {
      dismissLoading()
    }
  }, [createLoading, dismissLoading, presentLoading])

  const [present] = useIonToast()

  const handleCancel = (): void => {
    if (maxi > 0 && step_three.length === maxi) {
      onCancel()
      setShowModal(false)
    }

    if (maxi > 0 && step_three.length !== maxi) {
      presentToastMaxi(maxi || 0)
    } else {
      onCancel()
      setShowModal(false)
    }
  }

  const presentToast = useCallback(() => {
    present({
      message:
        'Désolé, vous avez sélectionné un utilisateur par défaut lors de la création du fiche.',
      duration: 4000,
      position: 'bottom',
    })
  }, [present])

  const presentToastMaxi = useCallback(
    (id: number) => {
      present({
        message: `Vous devez sélectionner ${id} usager${
          id > 1 ? 's' : ''
        } minimum`,
        duration: 4000,
        position: 'bottom',
      })
    },
    [present],
  )
  const handleDeleteItem = (
    id: number | string,
    usager: UsagerEntity,
  ): void => {
    if (!backdropDismiss) {
      dispatch(removeStepThree({ id, usager }))
      if (nbUnknownUsagers > 0) {
        dispatch(switchNbUnkown())
      }
    } else {
      presentToast()
    }
  }

  return (
    <IonModal
      ref={modal}
      isOpen
      initialBreakpoint={0.1}
      breakpoints={[0.1, 0.5, 0.9]}
      backdropDismiss={false}
      backdropBreakpoint={0.5}
      className="swipe-modal modal-inconnu"
    >
      <IonContent className="ion-padding">
        <IonRow>
          <IonCol>
            <div className="select-action">
              {step_three.length > 0 && (
                <IonText>
                  {step_three.length}{' '}
                  {step_three.length <= 1
                    ? 'usager sélectionné(e)'
                    : 'usagers sélectionné·e·s'}
                </IonText>
              )}

              {step_three.length > 0 && (
                <IonButton
                  color="primary"
                  expand="block"
                  size="small"
                  className="btn-small-white"
                  onClick={() => {
                    handleCancel()
                  }}
                >
                  Valider
                </IonButton>
              )}
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="category-selected-usager-row">
          <IonCol className="ion-no-padding">
            {step_three.map((usager) => (
              <div
                className="selected-usager"
                key={usager.attributes?.createdAt}
              >
                <UsagerListItem
                  usager={usager}
                  key={`${usager.id}selected`}
                  isSelected
                  isAction
                />
                <IonIcon
                  className="delete"
                  key={`${usager.id}icon`}
                  onClick={() => handleDeleteItem(usager?.id || '', usager)}
                />
              </div>
            ))}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonModal>
  )
}

export default ModalUsagerSelectedEdit
