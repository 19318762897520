// eslint-disable-next-line import/no-extraneous-dependencies
import { Toast, ShowOptions } from '@capacitor/toast';
import { useIonToast } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

const useToast = ({position = 'top', text}: ShowOptions):  (overText?: string) => void  => {
  const [present] = useIonToast();
  return async (overText?) => {
    if (Capacitor.isNativePlatform()) {
      await Toast.show({
        text: overText ||text,
        position
      });
    } else {
      await present(text, 7000);
    }
  }
};

export default useToast;
