/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Capacitor, Plugins } from '@capacitor/core'
import {
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonInput,
  IonModal,
} from '@ionic/react'
import { format, parse } from 'date-fns'
import { FC, useCallback, useEffect, useState } from 'react'
import './AppDatepicker.css'

export interface IAppDatepickerProps {
  name: string
  label: string
  initialValue?: any
  icon?: any
  min?: Date
  max?: Date
  presentation?:
    | 'month-year'
    | 'date'
    | 'date-time'
    | 'month'
    | 'time'
    | 'time-date'
    | 'year'
  displayFormat: string // eg: 'MMM dd yyyy'
  preferWheel?: boolean
  disabled?: boolean
  onChange?: (e: any) => void
}

const DatePicker = Plugins.DatePickerPlugin as any
const selectedTheme = 'light'

const AppDatepicker: FC<IAppDatepickerProps> = ({
  name,
  label,
  preferWheel,
  initialValue,
  icon,
  min,
  max,
  presentation,
  disabled,
  displayFormat = 'dd/MM/yyyy',
  onChange,
}: IAppDatepickerProps) => {
  const [popoverDate, setPopoverDate] = useState('')
  const [value, setValue] = useState<string | undefined>()

  // format for popoverDate
  const formatDate = useCallback(
    (val: string) => {
      const parsedDate = parse(val, displayFormat, new Date())
      if (displayFormat === 'MM/yyyy') {
        setValue(val)
        return format(new Date(val), displayFormat)
      }
      if (new Date(val) instanceof Date) {
        return format(new Date(val), displayFormat)
      }
      const newVal = format(new Date(parsedDate), displayFormat)
      return format(new Date(newVal), displayFormat)
    },
    [displayFormat, displayFormat],
  )

  const updateDateValue = (e: any) => {
    setValue(new Date(e.target.value).toISOString())
    setPopoverDate(formatDate(e.target.value))
    if (onChange) onChange(e)
  }

  useEffect(() => {
    if (initialValue) {
      setPopoverDate(formatDate(initialValue))
    }
    return () => {
      setPopoverDate('')
    }
  }, [initialValue, formatDate, name])

  const openNativePicker = async () => {
    if (Capacitor.isNativePlatform() && presentation === 'date') {
      const date = await DatePicker.present({
        mode: 'date',
        // min: min ? min.toISOString() : undefined,
        // max: max ? max.toISOString() : undefined,
        locale: 'fr_FR',
        format: displayFormat,
        date: popoverDate || undefined,
        theme: selectedTheme,
      })

      if (date) {
        const parsedDate = parse(date.value, displayFormat, new Date())
        const val = format(new Date(parsedDate), displayFormat)
        setPopoverDate(formatDate(parsedDate.toISOString()))
        setValue(parsedDate.toISOString())
      }
    }
  }

  return (
    <>
      <div
        id={name}
        onClick={() => openNativePicker()}
        className="ionic_custom_input"
      >
        <IonDatetimeButton
          datetime={`datetime-${name}`}
          className="ion-justify-content-start"
          disabled={disabled}
        >
          <IonInput
            disabled={disabled}
            slot="date-target"
            label={label}
            mode="md"
            labelPlacement="floating"
            readonly
            value={popoverDate}
            type="text"
          />
        </IonDatetimeButton>
        {icon ? (
          <IonIcon
            slot={icon?.slot}
            icon={icon?.icon}
            onClick={(e) => (icon?.onClick ? icon?.onClick(e) : false)}
          />
        ) : null}
        {!Capacitor.isNativePlatform() || presentation !== 'date' ? (
          <IonModal keepContentsMounted>
            <IonDatetime
              id={`datetime-${name}`}
              preferWheel={preferWheel}
              mode="ios"
              size="fixed"
              name={name}
              min={min ? min?.toISOString() : undefined}
              value={initialValue? new Date(initialValue).toISOString() : null}
              max={max ? max?.toISOString() : undefined}
              color="primary"
              presentation={presentation || 'month-year'}
              onIonChange={(e) => updateDateValue(e)}
            />
          </IonModal>
        ) : (
          <input
            type="hidden"
            name={name}
            onChange={(e) => updateDateValue(e)}
          />
        )}
      </div>
      {/* errors && errors[name] && <IonText className="ion-padding" color="danger"><small>{errors[name]?.message}</small></IonText> */}
    </>
  )
}

export default AppDatepicker
