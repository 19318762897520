/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import { useLazyQuery } from '@apollo/client'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  useIonToast,
  useIonViewDidEnter,
} from '@ionic/react'
import { close } from 'ionicons/icons'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { ModalLayout } from 'src/components'

import ModalActionSelected from 'src/components/Modals/ConfirmModal/ModalActionSelected'
import ModalUsagerSelected from 'src/components/Modals/ConfirmModal/ModalUsagerSelected'
import { LIST_ACTION_TYPES } from 'src/graphql/actions-types.graphql'
import { ActionType, ActionTypeEntity } from 'src/models/gql/graphql'
import {
  resetActionTypeSelected,
  resetFicheActions,
  setCurrentStep,
  setLoading,
  setLoadingSucceeded,
} from 'src/stores/slices/ficherActionsSlices'

import store, { RootState } from 'src/stores/store'
import {
  fetchOrientationData,
  fetchServiceData,
  fetchUsagers,
  getServicesUserSocialConnected,
  getUsagerDefaultSelected,
} from 'src/stores/thunks/fiche-actions-thunks'
import { getOrgId, getUserId } from 'src/utils/auth'
import StepFour from '../stepper-actions/step-four'
import StepOne from '../stepper-actions/step-one'
import StepThree from '../stepper-actions/step-three'
import StepTwo from '../stepper-actions/step-two'

const stepper = [1, 2, 3, 4]
const stepperDefault = [1, 2, 3]
interface IFormState {
  submittedStep1: boolean
  submittedStep2: boolean
  submittedStep3: boolean
}

const initialFormState: IFormState = {
  submittedStep1: false,
  submittedStep2: false,
  submittedStep3: false,
}

const CreateActions: React.FC = () => {
  const [isSearchAction, setIsSearchAction] = useState(false)
  const [isSearchUsager, setIsSearchUsager] = useState(false)
  const history = useHistory()
  const [results, setResult] = useState<ActionType[]>([])
  const [query, setQuery] = useState('')
  const [queryUsager, setQueryUsager] = useState('')
  const { id } = useParams<{ id: string }>()
  const [formState, setFormState] = useState<IFormState>(initialFormState)

  const handleSubmitStep1 = (): void => {
    setFormState((prevState) => ({
      ...prevState,
      submittedStep1: true,
    }))
  }

  const handleSubmitStep2 = (): void => {
    setFormState((prevState) => ({
      ...prevState,
      submittedStep2: !formState.submittedStep2,
    }))
  }

  const handleSubmitStep3 = (): void => {
    setFormState((prevState) => ({
      ...prevState,
      submittedStep3: !formState.submittedStep3,
    }))
  }
  const handleSearch = (e: any): void => {
    setQuery(e?.target.value)
  }

  const handleSearchUsager = (e: any): void => {
    setQueryUsager(e?.target.value)
  }

  const handleClear = (): void => {
    setQuery('')
    setResult([])
    setIsSearchAction(false)
  }

  const ficheList = (): void => {
    setFormState((prevState) => ({
      ...prevState,
      submittedStep1: false,
    }))
    history.replace('/main/actions')
  }

  const restart = (): void => {
    store.dispatch(resetFicheActions())
    setFormState((prevState) => ({
      ...prevState,
      submittedStep1: false,
    }))
    history.replace('/create/action')
    initForm()
  }

  const handleClearUsager = (): void => {
    setQueryUsager('')
    setIsSearchUsager(false)
    store.dispatch(
      fetchUsagers({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },
        sort: ['updatedAt:DESC'],
      }),
    )
  }

  const handleKeyPress = (e: any): void => {
    if (e.key === 'Enter') {
      setQuery(e.target.value)
      setIsSearchAction(true)
      searchActions(e.target.value)
      dispatch(resetActionTypeSelected())
    }
  }

  const handleKeyPressUsager = (e: any): void => {
    if (e.key === 'Enter') {
      setQueryUsager(e.target.value)
      setIsSearchUsager(true)
      searchActionUsager()
    }
  }

  const searchAction = (): void => {
    if (query.length > 0) {
      setIsSearchAction(true)
      searchActions(query || '')
      dispatch(resetActionTypeSelected())
    }
  }

  const searchActionUsager = (): void => {
    if (queryUsager.length > 0) {
      setIsSearchUsager(true)
      store.dispatch(
        fetchUsagers({
          filters: {
            and: [
              {
                or: [
                  { firstname: { containsi: queryUsager } },
                  { lastname: { containsi: queryUsager } },
                  { email: { containsi: queryUsager } },
                  { famillyCode: { containsi: queryUsager } },
                  { addressCity: { containsi: queryUsager } },
                  { phone: { containsi: queryUsager } },
                ],
              },

              {
                organization: { id: { eq: getOrgId() } },
              },
            ],
          },
          pagination: { page: 1, pageSize: 52 },
          sort: ['updatedAt:DESC'],
        }),
      )
    }
  }

  useIonViewDidEnter(() => {
    initForm()
  })

  const initForm = (): void => {
    store.dispatch(getServicesUserSocialConnected({ id: getUserId() || '' }))
    store.dispatch(
      fetchServiceData({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },

            {
              users: { id: { in: getUserId() } },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },

        sort: ['updatedAt:DESC'],
      }),
    )

    store.dispatch(
      fetchOrientationData({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },
        sort: ['updatedAt:DESC'],
      }),
    )
  }

  const {
    currentStep,
    selectActionType,
    step_three,
    step_two,
    step_one,
    isUsagerDefault,

    nbUnkown,
  } = useSelector((state: RootState) => state.ficheActions)

  const dispatch = useDispatch()

  const [getActionCategories, { loading, data }] = useLazyQuery(
    LIST_ACTION_TYPES,
  )
  const [present] = useIonToast()

  const presentToast = useCallback(() => {
    present({
      message: `S'il vous plaît remplir les champs obligatoires`,
      duration: 4000,

      position: 'bottom',
    })
  }, [present])

  const presentToastStepper = useCallback(
    (step: number, stepVerify: number): void => {
      present({
        message: `Vous ne pouvez pas passer directement à l'étape ${step} , vérifiez les actions à faire sur l'écran etape ${stepVerify}.`,
        duration: 4000,

        position: 'bottom',
      })
    },
    [present],
  )

  const validationStepOne = (): void => {
    if (
      currentStep === 1 &&
      //  step_one.description &&
      step_one.service &&
      step_one.orientation
    ) {
      dispatch(setCurrentStep(currentStep + 1))
    } else {
      presentToast()
    }
  }

  const handleNext = (): void => {
    switch (currentStep) {
      case 1:
        handleSubmitStep1()
        validationStepOne()
        break
      case 2:
        handleSubmitStep2()
        dispatch(setCurrentStep(currentStep + 1))
        break
      case 3:
        handleSubmitStep3()
        dispatch(setCurrentStep(currentStep + 1))
        break
      default:
        dispatch(setCurrentStep(currentStep + 1))
    }
  }

  const searchActions = (term?: string): void => {
    getActionCategories({
      variables: {
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
              name: { containsi: term },
            },
          ],
        },
        sort: ['updatedAt:DESC'],
      },
    })
  }

  const segmentClick = (step: number): void => {
    switch (step) {
      case 2:
        handleSubmitStep1()
        if (step_one.service && step_one.orientation) {
          dispatch(setCurrentStep(step))
          setQuery('')
          setQueryUsager('')
        } else {
          presentToastStepper(step, 1)
        }
        break
      case 3:
        if (step_two.length > 0) {
          dispatch(setCurrentStep(step))
          setQuery('')
          setQueryUsager('')
        } else {
          presentToastStepper(step, 2)
        }
        break

      case 4:
        presentToastStepper(step, 3)

        break

      default:
        dispatch(setCurrentStep(1))
    }
  }

  useEffect(() => {
    if (selectActionType || !selectActionType) {
      handleClear()
    }
  }, [selectActionType])

  useEffect(() => {
    if (id) {
      store.dispatch(
        getUsagerDefaultSelected({
          id: id.toString(),
        }),
      )
    }
    if (currentStep === 4) {
      setFormState(initialFormState)
    }
  }, [currentStep, id])

  const checkActionAlreadyAdded = useCallback(
    (checking: ActionTypeEntity[]): ActionType[] => {
      const arrayCheck = step_two.map((res) => {
        return res.id
      })

      const response = checking.filter((res) => {
        return res.id && !arrayCheck.includes(res.id)
      })
      setResult((response as unknown) as ActionType[])

      return response as ActionType[]
    },
    [step_two],
  )

  useEffect(() => {
    if (loading) {
      dispatch(setLoading())
    } else {
      dispatch(setLoadingSucceeded())
      if (
        step_two.length > 0 &&
        data?.actionTypes &&
        data?.actionTypes?.data.length > 0
      ) {
        checkActionAlreadyAdded(data?.actionTypes?.data as ActionTypeEntity[])
      } else {
        setResult(data?.actionTypes?.data as ActionType[])
      }
    }
    return () => {
      dispatch(setLoadingSucceeded())
    }
  }, [loading, dispatch, data, step_two.length, checkActionAlreadyAdded])

  const handleBack = (): void => {
    history.replace('/main/actions')
    setFormState((prevState) => ({
      ...prevState,
      submittedStep1: false,
    }))
  }

  const renderStepTitile = (): string => {
    switch (currentStep) {
      case 1:
        return 'Renseigner les informations'
      case 2:
        return 'Sélectionner le(s) action(s) que vous souhaitez enregistrer.'
      case 3:
        return 'Sélectionnez l(es) usager(s) lié(s) à l’action.'
      case 4:
        return ''
      default:
        return 'Renseigner les informations'
    }
  }
  const renderStepContent = (): JSX.Element => {
    switch (currentStep) {
      case 1:
        return <StepOne submittedStep1={formState.submittedStep1} />
      case 2:
        return <StepTwo isSearch={isSearchAction} resultSearch={results} />
      case 3:
        return <StepThree isSearch={isSearchUsager} />
      case 4:
        return <StepFour />
      default:
        return <div>Default step </div>
    }
  }

  return (
    <IonPage>
      <ModalLayout
        title="Nouvelle fiche action"
        classNameColor="new-actions"
        onBack={currentStep < 4 ? handleBack : undefined}
      >
        <IonGrid className="wrap-stepper">
          <IonRow>
            <IonCol size="12">
              <div>
                {currentStep < 4 && !isUsagerDefault && (
                  <IonSegment
                    value={currentStep.toString()}
                    className="stepper"
                  >
                    {stepper.map((step) => (
                      <IonSegmentButton
                        onClick={() => segmentClick(step)}
                        value={step.toString()}
                        className={
                          currentStep > step || currentStep === step
                            ? 'active'
                            : 'no-active'
                        }
                      >
                        <div className="step-number">
                          <span className="round">{step}</span>
                        </div>
                      </IonSegmentButton>
                    ))}
                  </IonSegment>
                )}

                {currentStep < 3 && isUsagerDefault && (
                  <IonSegment
                    value={currentStep.toString()}
                    className="stepper"
                  >
                    {stepperDefault.map((step) => (
                      <IonSegmentButton
                        onClick={() => segmentClick(step)}
                        value={step.toString()}
                        className={
                          currentStep > step || currentStep === step
                            ? 'active'
                            : 'no-active'
                        }
                      >
                        <div className="step-number">
                          <span className="round">{step}</span>
                        </div>
                      </IonSegmentButton>
                    ))}
                  </IonSegment>
                )}
                <div className="step-title">{renderStepTitile()}</div>
                {currentStep === 2 && !selectActionType && (
                  <IonRow>
                    <IonCol className="ion-no-padding">
                      <IonItem className="list-search">
                        <IonInput
                          type="text"
                          labelPlacement="stacked"
                          value={query}
                          onInput={handleSearch}
                          placeholder="Rechercher une action"
                          onKeyPress={handleKeyPress}
                        />
                        {query.length > 0 && (
                          <IonIcon
                            icon={close}
                            onClick={handleClear}
                            id="clear-step"
                            style={{ cursor: 'pointer' }}
                            className="icon-close"
                          />
                        )}
                        <IonIcon
                          onClick={() => searchAction()}
                          style={{ cursor: 'pointer' }}
                          className="icon-search"
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                )}
                {currentStep === 3 && (
                  <IonGrid className="ion-no-padding">
                    <IonRow>
                      <IonCol className="ion-no-padding">
                        <IonItem className="list-search ion-margin-bottom">
                          <IonInput
                            type="text"
                            disabled={isUsagerDefault}
                            labelPlacement="stacked"
                            value={queryUsager}
                            placeholder="Rechercher des usagers"
                            onInput={handleSearchUsager}
                            onKeyPress={handleKeyPressUsager}
                          />
                          {queryUsager.length > 0 && (
                            <IonIcon
                              icon={close}
                              onClick={handleClearUsager}
                              style={{ cursor: 'pointer' }}
                              className="icon-close"
                            />
                          )}
                          <IonIcon
                            onClick={() => searchActionUsager()}
                            style={{ cursor: 'pointer' }}
                            className="icon-search"
                          />
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="tabs-content">
          <IonRow>
            <IonCol size="12">
              <div className="step-content">{renderStepContent()}</div>

              {step_two.length > 0 &&
                !selectActionType &&
                currentStep === 2 && (
                  <ModalActionSelected isOpen={step_two.length > 0} />
                )}

              {step_three.length > 0 && currentStep === 3 && (
                <ModalUsagerSelected
                  isOpen={step_three.length > 0}
                  backdropDismiss={isUsagerDefault}
                />
              )}

              {nbUnkown > 0 && currentStep === 3 && (
                <ModalUsagerSelected isOpen={nbUnkown > 0} />
              )}
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className="step-buttons">
                {currentStep === 1 && (
                  <IonButton
                    color="primary next"
                    expand="block"
                    size="large"
                    onClick={handleNext}
                  >
                    Continuer
                  </IonButton>
                )}

                {currentStep === 4 && (
                  <>
                    <IonButton
                      color="primary send ion-margin-bottom"
                      expand="block"
                      size="large"
                      onClick={restart}
                    >
                      Créer d’autres actions
                    </IonButton>
                    <IonButton
                      color="primary send"
                      expand="block"
                      size="large"
                      fill="outline"
                      onClick={ficheList}
                    >
                      Accéder à la liste des fiches action
                    </IonButton>
                  </>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ModalLayout>
    </IonPage>
  )
}

export default CreateActions
