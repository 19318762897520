import {
  IonButton,
  IonCol,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react'
import { useHistory } from 'react-router'
import { ModalLayout } from 'src/components'

const CrSuccess: React.FC = () => {
  const history = useHistory()
  const handleBack = (): void => {
    history.replace('/main/transmissions')
  }

  return (
    <IonPage>
      <ModalLayout title="Confirmation">
        <IonGrid className="validated">
          <IonRow className="ion-margin-top">
            <IonCol>
              <IonImg src="/assets/images/validated.svg" alt="Tick" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="title ion-text-center">
                La note transmission est envoyée
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                color="primary next"
                expand="block"
                size="large"
                fill="outline"
                onClick={handleBack}
              >
                Retourner à la liste des transmissions
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ModalLayout>
    </IonPage>
  )
}

export default CrSuccess
