/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLazyQuery } from '@apollo/client'

import { IonSearchbarCustomEvent } from '@ionic/core'

import {
  IonBadge,
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonSearchbar,
  SearchbarChangeEventDetail,
  useIonLoading,
  useIonToast,
  useIonViewDidEnter,
} from '@ionic/react'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import ActionListItem from 'src/components/ActionListItem/ActionListItem'
import { GET_ACTIONS_LIST } from 'src/graphql/actions-types.graphql'
import { ActionEntity } from 'src/models/gql/graphql'
import { getOrgId } from 'src/utils/auth'
import styled from 'styled-components'

/* interface GroupedActionEntity {
  count: number
  [key: string]: unknown // accepte les clés de type chaîne de caractères
}
export interface ActionEntityWithCount extends ActionEntity {
  count: number
} */

const UsagersActions: React.FC = () => {
  const [presentLoading, dismissLoading] = useIonLoading()
  const [search, setSearch] = useState<string>()
  const [iSearch, setIsSearch] = useState<boolean>()
  const [getActions, { loading, data, error }] = useLazyQuery(GET_ACTIONS_LIST)
  const [actionsData, setActions] = useState<ActionEntity[] | undefined>()
  const [present] = useIonToast()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const handleSearchClick = (
    event: IonSearchbarCustomEvent<SearchbarChangeEventDetail>,
  ): void => {
    // fonction à appeler lors du clic sur l'icône de recherche
    getData(event.detail.value || '')
    event.preventDefault()
    setIsSearch(true)
  }
  const clear = (event: IonSearchbarCustomEvent<void>): void => {
    setSearch('')
    setIsSearch(false)

    event.preventDefault()
  }
  const presentToast = useCallback(() => {
    present({
      message:
        "Une erreur est survenue lors de l'operation. Veuillez nous excuser pour ce désagrément. Pouvez-vous réessayer plus tard ou contacter notre équipe de support pour obtenir de l'aide supplémentaire",
      duration: 6000,
      position: 'bottom',
    })
  }, [present])

  useEffect(() => {
    if (loading) {
      presentLoading()
    } else {
      dismissLoading()
    }
    if (error) {
      presentToast()
    }
    return () => {
      dismissLoading()
    }
  }, [dismissLoading, error, loading, presentLoading, presentToast])

  /* const groupByResult = (actions: ActionEntity[]): ActionEntityWithCount[] => {
    const groupByActionTypeId = actions.reduce((accumulator, currentValue) => {
      const actionTypeId = currentValue.attributes?.actionType?.data?.id
      if (actionTypeId) {
        if (!accumulator[actionTypeId]) {
          accumulator[actionTypeId] = {
            count: 1,
            ...currentValue,
          }
        } else {
          // accumulator[actionTypeId].count++
          const { count } = accumulator[actionTypeId]
          accumulator[actionTypeId].count =
            typeof count === 'number' ? count + 1 : 1
        }
      }
      return accumulator
    }, {} as { [key: string]: GroupedActionEntity })

    return Object.values(groupByActionTypeId) as ActionEntityWithCount[]
  } */

  useIonViewDidEnter(async () => {
    const { data: currentData } = await getActions({
      notifyOnNetworkStatusChange: true,
      variables: {
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },

            {
              usagers: { id: { in: (id as unknown) as never } },
            },
          ],
        },
        sort: ['actionDate:DESC'],
      },
    })
    setActions(currentData?.actions?.data as ActionEntity[])
  })

  const getData = (term?: string): void => {
    setSearch(term)
    if (term) {
      return setActions(() =>
        (data?.actions?.data as ActionEntity[]).filter((note) => {
          return (
            note &&
            note.attributes?.actionType?.data?.attributes?.name
              ?.toLocaleLowerCase()
              .includes(term.toLocaleLowerCase())
          )
        }),
      )
    }
    return setActions(data?.actions?.data as [])
  }

  return (
    <UsagerActionWrapper>
      {!loading && actionsData && (
        <>
          <IonRow>
            <IonCol className="ion-no-padding">
              <div className="ion-align-items-center d-flex research">
                <IonLabel className="ion-label">
                  Rechercher une action parmi les
                </IonLabel>
                <IonBadge className="counter" color="primary">
                  {actionsData.length}
                </IonBadge>
              </div>
              <IonSearchbar
                enterkeyhint="search"
                showClearButton="never"
                type="search"
                mode="md"
                placeholder="Nom de l’action"
                inputmode="search"
                // onKeyUp={(e: any) => { if(e.key === "Enter") handleSearchClick(e) }}
                value={search}
                debounce={700}
                onIonChange={(event) => handleSearchClick(event)}
                onIonClear={(e) => clear(e)}
              />
            </IonCol>
          </IonRow>
          {actionsData &&
            actionsData.length > 0 &&
            actionsData?.map((note: ActionEntity) => (
              <ActionListItem action={note} key={note.id} actionId={id} />
            ))}
        </>
      )}

      {!loading && actionsData?.length === 0 && !iSearch && (
        <IonRow>
          <IonCol className="ion-text-center">
            Cet usager n'a pas encore une fiche action
          </IonCol>
        </IonRow>
      )}

      {!loading && actionsData?.length === 0 && iSearch && (
        <IonRow>
          <IonCol className="ion-text-center">Aucun résultat trouvé</IonCol>
        </IonRow>
      )}
      {!loading && (
        <IonRow>
          <IonCol>
            <IonButton
              className="button-large action-btn"
              expand="block"
              fill="solid"
              onClick={() => history.replace(`/view/${id}/actions/create`)}
            >
              Ajouter une action
            </IonButton>
          </IonCol>
        </IonRow>
      )}
    </UsagerActionWrapper>
  )
}
const UsagerActionWrapper = styled(IonGrid)`
  ion-searchbar {
    // ion-icon {
    //   right: 16px !important;
    //   left: auto !important;
    // }
    .searchbar-input {
      --padding-start: 5px;
    }
    .searchbar-clear-button {
      right: 60px;
    }
  }
  ion-badge {
    margin-left: 5px;
    border-radius: 50%;
    padding: 2%;
  }
`

export default UsagersActions
