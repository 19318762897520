/* eslint-disable import/no-duplicates */

import {
  differenceInDays,
  format,
  formatDistanceToNowStrict,
  isValid,
  parseISO,
} from 'date-fns';
import { fr } from 'date-fns/locale';

export const formatDate = (date?: string | Date): string => {
  if (!date) {
    return '-';
  }
  return format(new Date(date), 'dd/MM/yyyy');
};

export const formatDateInsertion = (date?: string): string => {
  if (!date) {
    return '-';
  }
  return format(new Date(date), 'yyyy-MM-dd');
};

export const formatUserName = (user?: any): string => {
  if (!user || (!user.lastname && !user.firstname)) {
    return '-';
  }
  return `${user.firstname || ''} ${user.lastname || ''}`;
};

export const formatingDateText = (daty: string): string => {
  const date = parseISO(daty);
  const today = new Date();

  // Valider la date pour s'assurer que les heures et les minutes sont valides
  if (!isValid(date)) {
    return 'Date invalide';
  }

  const diffInDays = differenceInDays(today, date);
  let result;

  if (diffInDays === 0) {
    result = `Aujourd'hui  à ${date.getHours()}h${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else if (diffInDays < 1) {
    result = formatDistanceToNowStrict(date, { locale: fr, addSuffix: true });
  } else if (diffInDays === 1) {
    result = `Hier à ${date.getHours()}h${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else if (diffInDays === 2) {
    result = `Avant-hier à ${date.getHours()}h${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else {
    result = `${date.toLocaleDateString('fr-FR')} à ${date.getHours()}h${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  }

  return result;
};
