import { useLazyQuery } from '@apollo/client'
import {
  IonCol,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  useIonLoading,
  useIonViewDidEnter,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ModalLayout } from 'src/components'
import { LIST_USAGERS } from 'src/graphql/usagers.graphql'
import { UsagerEntity } from 'src/models/gql/graphql'
import UsagersMap from 'src/pages/Main/usagers/segment/usagers-maps/Usagers-map'
import UsagersList from 'src/pages/Main/usagers/segment/usagers/Usagers-list'
import { getOrgId } from 'src/utils'
import styled from 'styled-components'

const Usagers: React.FC = () => {
  const history = useHistory()
  const [selectedSegment, setSelectedSegment] = useState('usagers')
  const [presentLoading, dismissLoading] = useIonLoading()
  const [getUsagers, { loading, data }] = useLazyQuery(LIST_USAGERS)
  const [search, setSearch] = useState<string | undefined>()
  useIonViewDidEnter(() => {
    getUsagersData()
  })

  const getUsagersData = (term?: string): void => {
    setSearch(term)
    getUsagers({
      notifyOnNetworkStatusChange: true,
      variables: {
        filters: {
          and: [
            {
              or: [
                { firstname: { containsi: term } },
                { lastname: { containsi: term } },
                { email: { containsi: term } },
                { famillyCode: { containsi: term } },
                { addressCity: { containsi: term } },
                { phone: { containsi: term } },
              ],
            },
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        pagination: {limit: 250}, 
        sort: ['updatedAt:DESC'],
      },
    })
  }
  useEffect(() => {
    if (loading) {
      presentLoading()
    } else {
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading])

  const handleSegmentChange = (event: CustomEvent): void => {
    setSelectedSegment(event.detail.value)
    if (event.detail.value === 'usagers') {
      getUsagersData()
    }
  }
  const onSearch = (term?: string): void => {
    getUsagersData(term)
  }

  return (
    <UsagersWrapper className={selectedSegment === 'cartes' ? 'has-map' : 'without-map'}>
      <ModalLayout title="Liste des usagers">
        <IonGrid
          className="list-usagers"
          style={{ paddingTop: 1, paddingLeft: 1 }}
        >
          <IonRow className="usagers-segments ion-justify-content-center">
            <IonCol size="11">
              <IonSegment
                color="primary"
                mode="ios"
                value={selectedSegment}
                onIonChange={handleSegmentChange}
              >
                <IonSegmentButton value="usagers">
                  <IonLabel>Usagers</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="cartes">
                  <IonLabel>Carte</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
          <div className={`usagers-segments-content ${selectedSegment === 'cartes' ? '' : 'no-map'}`}>
            {selectedSegment === 'usagers' && !loading && (
              <UsagersList
                usagersList={data?.usagers?.data as UsagerEntity[]}
                onSearch={onSearch}
                search={search}
              />
            )}
            {selectedSegment === 'cartes' && (
              <UsagersMap usagersList={data?.usagers?.data as UsagerEntity[]} />
            )}
          </div>
        </IonGrid>
      </ModalLayout>
    </UsagersWrapper>
  )
}

const UsagersWrapper = styled(IonPage)`
  .usagers-segments {
    position: fixed;
    width: 100%;
    z-index: 9999;
    right: 0;
    top: 104px;
    padding: 15px 0 10px;
    border-radius: 24px 24px 0 0;
  }
  .usagers-segments-content.no-map {
      margin-top: 40px;
  }
`
export default Usagers
