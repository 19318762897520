import {  IonCol,  IonGrid,  IonRow,  IonText } from '@ionic/react';

export interface ISuccessCardProps {
  title?: string;
  text?: string;
  icon?: any;
}

const SuccessCard: React.FC<ISuccessCardProps> = ({
  text,
  title,
  icon,
 }) => {
  return (
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center ion-padding-top">
              {icon}
              {title && <IonText><h3>{title}</h3></IonText>}
              {text && <IonText><p>{text}</p></IonText>}
              </IonCol>
          </IonRow>
        </IonGrid>
)};

export default SuccessCard;