/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {FC, useCallback, useEffect, useState} from 'react';
import { IonItem, IonLabel, IonIcon, IonInput, IonText, IonPopover, IonDatetime, IonModal, IonDatetimeButton} from '@ionic/react';
import styled from 'styled-components';
import { calendar, calendarClearOutline } from 'ionicons/icons';
import { format, parse} from 'date-fns';
import fr from 'date-fns/locale/fr/index'

export interface IAppStartDatePickerProps {
  className?: string;
  label: string;
  name: string;
  initialValue?: any;
  icon?: {icon: any, onClick?: (e: any) => void, slot: string };
  min?: string;
  max?: string;
  presentation?: 'month-year'|'date'|'date-time'|'month'|'time'|'time-date'|'year', 
  preferWheel?: boolean;
  disabled?: boolean;
  displayFormat: string; // eg: 'MMM dd yyyy'
  onChange?: (e: any) => void
}


 const AppStartDatePicker: FC<IAppStartDatePickerProps> = ({
    className,
    label,
    name,
    preferWheel,
    initialValue,
    icon,
    min,
    max,
    presentation,
    displayFormat,
    disabled,
    onChange
 }: IAppStartDatePickerProps) => {
  const [value, setValue] = useState<string|undefined>();
  const [popoverDate, setPopoverDate] = useState('');
  useEffect(() => {
    console.log('min', min)
  }, [min])
  const formatDate = useCallback( ((val: string) => {
    const parsedDate = parse(val, displayFormat, new Date());
    if (displayFormat === 'MM/yyyy') {
      setValue(val);
      return format(new Date(val), displayFormat);
    } 
    if (new Date(val) instanceof Date ) {
      return format(new Date(val), displayFormat, {locale: fr});
    }
    const newVal = format(new Date(parsedDate), displayFormat );
    return format(new Date(newVal), displayFormat);
    
  }), [displayFormat, displayFormat ]);

  const updateDateValue = (e: any) => {
    setPopoverDate(formatDate(e.target.value))
    setValue(new Date(e.target.value).toISOString());
    if (onChange) onChange(e);
  };
  useEffect(() => {
    if (initialValue ) {
      setPopoverDate(formatDate(initialValue))
    }
    return () => {
      setPopoverDate('');
    }
  }, [initialValue, formatDate]);
  
  return (
      <AppStartDatePickerWrapper className={className||'uniq'}>
        <div>   
            <IonDatetimeButton 
            datetime={`startdatetime-${name}`}
            className="ion-justify-content-start"
            disabled={disabled} >
              <div className="date-input" slot="date-target">
                {icon && icon.slot === 'start' && <IonIcon icon={calendarClearOutline} />}
                <IonInput 
                  disabled={disabled}
                  label={label}
                  mode="md"
                  fill="outline"
                  labelPlacement={className && className.split(' ').includes('range') ? "stacked" : "floating" }
                  readonly
                  value={popoverDate}
                  type="text"
                  />
              </div>
            </IonDatetimeButton>
              {
                  icon && icon.slot === 'end' && <IonIcon slot={icon?.slot}  icon={icon?.icon} onClick={e => icon?.onClick ? icon?.onClick(e) : false} /> 
              }
            <IonModal 
              keepContentsMounted
              >
              <IonDatetime
                min={min ||undefined}
                max={max ||undefined}
                showDefaultButtons
                cancelText="Annuler"
                doneText="Ok"
                disabled={disabled}
                name={name}
                id={`startdatetime-${name}`}
                mode="md"
                value={value}
                color="primary"
                presentation={presentation|| 'month-year'}
                onIonChange={(e) => updateDateValue(e)}
                />
            </IonModal>
        </div>
    </AppStartDatePickerWrapper>
);
};

const AppStartDatePickerWrapper = styled('div') `
&.uniq {
  width: 180px;
  margin-left: auto;
  ion-icon {
    font-size: 24px;
    margin-left: 10px;
  }
  ion-input {
    --padding-end: 0 !important;
    --border-width: 0 !important;
  }
  .has-value.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md {
    margin-top: 16px;
    right: 0;
    color: gray;
    font-size: 14px;
  }
  .date-input {
    height: 40px;
    border: 1px solid rgb(0, 0, 0) !important;
    display: flex;
    align-items: center;
    border-radius: 8px; 
  }
  .input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md{
    border:none !important;
    padding-top: 10px;
    margin: 0; 
    height: 52px; 
    border: 0;
    font-size: 14px; 
    line-height: 14px;
    transform: none !important;
    font-size: 14px;
    line-height: 14px;
    font-family: 'Inter-regular';
    font-weight: normal;
    color: #374151;
    text-align: right !important;
    --padding-end: 0;
  }
  .sc-ion-input-md-h:not(.legacy-input) {
    min-height: 40px !important;
    --padding-start:0;
    --padding-end: 0;
  }
}
&.range {
  .input-fill-outline.input-label-placement-stacked.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md {
    padding-bottom: 10px;
  }
  &.start .input-label-placement-stacked.sc-ion-input-md-h input.sc-ion-input-md {
    border-radius: 8px 0 0 8px;
  }
  &.end .input-label-placement-stacked.sc-ion-input-md-h input.sc-ion-input-md {
    border-radius: 0 8px 8px 0;
  }
  ion-input {
    --padding-end: 0 !important;
    --border-width: 0 !important;
    margin-bottom: 0 !important;
  }
  ion-icon {
    position: absolute;
    top: 40px;
    right: 15px;
  }
  .sc-ion-input-md-h:not(.legacy-input) {
    --padding-start:0;
    --padding-end: 0;
    --padding-top: 10px;
  }
}
`

 export default AppStartDatePicker;