/* eslint-disable camelcase */
import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonText,
  useIonToast,
} from '@ionic/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_ACTION_MUTATION } from 'src/graphql/actions-types.graphql'
import { UsagerEntity } from 'src/models/gql/graphql'
import {
  removeStepTwo,
  resetActionTypeSelected,
  setCurrentStep,
} from 'src/stores/slices/ficherActionsSlices'
import store, { RootState } from 'src/stores/store'
import { getOrgId, getUserId } from 'src/utils/auth'
import { formatDateInsertion } from 'src/utils/formatting'
import './ConfirmModal.css'

export interface IConfirmModalProps {
  isOpen?: boolean
}
const ModalActionSelected: React.FC<IConfirmModalProps> = ({ isOpen }) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const [, setShowModal] = useState(isOpen)
  const dispatch = useDispatch()
  const {
    step_two,
    currentStep,
    isUsagerDefault,
    step_three,
    step_one,
  } = useSelector((state: RootState) => state.ficheActions)
  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen, setShowModal])

  const handleDeleteItem = (id: number | string): void => {
    dispatch(removeStepTwo(id))
  }
  const [createAction, { loading }] = useMutation(CREATE_ACTION_MUTATION)

  const [present] = useIonToast()
  const presentToastError = useCallback(() => {
    present({
      message:
        "Une erreur s'est produite, veuillez contacter l'admin ou réessayer plus tard",
      duration: 4000,
      position: 'bottom',
    })
  }, [present])

  const getUsagers = (): string[] => {
    return step_three.map((res: UsagerEntity) => {
      return res?.id
    }) as []
  }

  const createFiches = async (): Promise<void> => {
    try {
      const actions = step_two.map((res) =>
        createAction({
          variables: {
            data: {
              author: getUserId(),
              organization: getOrgId(),
              usagers: step_three.length > 0 ? getUsagers() : [],
              actionDate: formatDateInsertion(new Date().toISOString()),
              actionType: res.id.toString(),
              quantity: res?.quantity,
              signalementDescription: step_one.description,
              orientationMadeBy: step_one.orientation,
              service: step_one.service,
            },
          },
        }),
      )
      await Promise.all(actions)
    } catch (e) {
      presentToastError()

      throw e
    }
  }

  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      initialBreakpoint={0.1}
      breakpoints={[0.1, 0.5, 0.8]}
      backdropDismiss={false}
      backdropBreakpoint={0.5}
      className="swipe-modal select-action-modal"
    >
      <IonContent className="ion-padding">
        <IonRow>
          <IonCol>
            <div className="select-action">
              <IonText>
                {step_two.length}{' '}
                {step_two.length <= 1
                  ? 'action sélectionnée'
                  : 'actions sélectionnées'}{' '}
              </IonText>
              <IonButton
                color="primary"
                expand="block"
                size="small"
                className="btn-small-white"
                onClick={() => {
                  if (!isUsagerDefault) {
                    store.dispatch(setCurrentStep(currentStep + 1))
                    store.dispatch(resetActionTypeSelected())
                  } else {
                    store.dispatch(setCurrentStep(4))
                    createFiches()
                  }
                }}
              >
                Continuer
              </IonButton>
            </div>
          </IonCol>
        </IonRow>

        {step_two.length > 0 && (
          <IonRow>
            <IonCol>
              <IonList className="list-selection">
                {step_two.map((step) => (
                  <IonItem className="list-selection-item" key={step.name}>
                    <IonLabel
                      className="list-selection-desc"
                      key={`${step.name} label`}
                    >
                      {step.quantity > 0 && (
                        <span className="quantity">{`${step.quantity}`}</span>
                      )}
                      {`${step.name}`}
                    </IonLabel>
                    <IonButton
                      slot="end"
                      fill="clear"
                      onClick={() => handleDeleteItem(step.id)}
                    >
                      <IonIcon className="delete" />
                    </IonButton>
                  </IonItem>
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        )}
      </IonContent>
    </IonModal>
  )
}

export default ModalActionSelected
