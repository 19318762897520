/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useMutation } from '@apollo/client'
import { IonImg, IonItem, IonLabel, IonText } from '@ionic/react'
import { FC, useState } from 'react'
import ModalTransmission from 'src/components/Modals/ConfirmModal/ModalTransmission'
import {
  DELETE_TRANSMISSION_NOTE,
  UPDATE_TRANSMISSION_NOTE,
} from 'src/graphql/transmissions-types.graphql'
import useToast from 'src/hooks/toast'
import { TransmissionNoteEntity } from 'src/models/gql/graphql'
import { getUserId } from 'src/utils'
import { formatingDateText } from 'src/utils/formatting'
import './TransmissionNoteItem.css'

export interface ITransmissionNoteProps {
  transmissionNote?: TransmissionNoteEntity
  onViewClick: (value?: string) => void
  onDeleteClick: (value?: string, isNew?: boolean) => void
}

const TransmissionListItem: FC<ITransmissionNoteProps> = ({
  transmissionNote,
  onViewClick,
  onDeleteClick,
}: ITransmissionNoteProps) => {
  const [viewMore] = useState(false)

  const [idSelected, setIdSelected] = useState('`')
  const [isNew, checkIsNew] = useState(false)
  const [updateAction] = useMutation(UPDATE_TRANSMISSION_NOTE)
  const [deleteTransmission] = useMutation(DELETE_TRANSMISSION_NOTE)

  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
  const toaster = useToast({
    text: 'Note transmission supprimée avec succès !',
    position: 'bottom',
  })
  const confirmActions = {
    onCancel: () => modalCancel(),
  }

  const onConfirmModal = async (id?: string): Promise<void> => {
    if (id)
      await deleteTransmission({
        variables: {
          id,
        },
      })
    onDeleteClick(id, isNew)
    setOpenConfirmation(false)
    toaster()
  }

  const modalCancel = async (): Promise<void> => {
    setOpenConfirmation(false)
    if (isNew && idSelected) {
      onViewClick(idSelected)
      await updateAction({
        variables: {
          id: idSelected,
          data: {
            isNew: false,
          },
        },
      })
    }
  }

  const handleClickView = (
    id: string,
    news: boolean,
    authorId: number | string,
  ): void => {
    setOpenConfirmation(true)
    setIdSelected(id)
    if (authorId.toString() === getUserId()?.toString()) {
      checkIsNew(false)
    } else {
      checkIsNew(news)
    }
  }

  // const [messageVocal, setMessageVocal] = useState('')
  // const [transcription, setTranscription] = useState('')
  // const [estEnregistrementEnCours, setEstEnregistrementEnCours] = useState(
  //   false,
  //  )

  /* const demarrerEnregistrement = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream)
        const audioChunks = [] as any

        mediaRecorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data)
        })

        mediaRecorder.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunks)
          const audioUrl = URL.createObjectURL(audioBlob)
          const audio = new Audio(audioUrl)

          // Lecture de l'enregistrement vocal
          //  audio.play()

          const recognition = new window.webkitSpeechRecognition()
          recognition.lang = 'fr-FR'
          recognition.addEventListener('result', (event: any) => {
            console.log(event.results)
            const resultatTranscription = event.results[0][0].transcript
            setTranscription(resultatTranscription)
          })
          recognition.start()
        })

        mediaRecorder.start()
        setEstEnregistrementEnCours(true)

        setTimeout(() => {
          mediaRecorder.stop()
          setEstEnregistrementEnCours(false)
        }, 15000)
      })
      .catch((error) => console.error(error))
  } */

  return (
    <>
      <ModalTransmission
        isOpen={openConfirmation}
        id={transmissionNote?.id?.toString() || ''}
        text={transmissionNote?.attributes?.description || ''}
        canEdit={
          getUserId()?.toString() ===
          transmissionNote?.attributes?.author?.data?.id
        }
        daty={formatingDateText(transmissionNote?.attributes?.createdAt)}
        author={`${transmissionNote?.attributes?.author?.data?.attributes?.firstname} ${transmissionNote?.attributes?.author?.data?.attributes?.lastname}`}
        okText="Confirmer"
        onConfirm={onConfirmModal}
        onCancel={confirmActions.onCancel}
      />
      <IonItem
        className="transmission-item"
        onClick={() =>
          handleClickView(
            transmissionNote?.id || '',
            transmissionNote?.attributes?.isNew || false,
            transmissionNote?.attributes?.author?.data?.id || '',
          )
        }
      >
        <div className="ion-align-items-center actions--item">
          <div className="actions--counter">
            <IonLabel id={`transmisionNote${transmissionNote?.id}`}>
              {transmissionNote?.attributes?.description && (
                <h3>
                  {viewMore && transmissionNote?.attributes?.description
                    ? transmissionNote?.attributes?.description
                    : `${transmissionNote?.attributes?.description.slice(
                        0,
                        150,
                      )}${
                        transmissionNote?.attributes?.description.length > 123
                          ? ' ...'
                          : ''
                      }`}{' '}
                  {/* transmissionNote?.attributes?.description.length > 123 && (
                    <span onClick={() => setViewMore(!viewMore)}>
                      {viewMore ? 'Voir moins' : 'Voir plus'}
                    </span>
                  ) */}
                </h3>
              )}
            </IonLabel>
            <IonText className="name">
              {formatingDateText(transmissionNote?.attributes?.createdAt)} •{' '}
              {getUserId()?.toString() ===
              transmissionNote?.attributes?.author?.data?.id
                ? 'Moi'
                : `${transmissionNote?.attributes?.author?.data?.attributes?.firstname} ${transmissionNote?.attributes?.author?.data?.attributes?.lastname}  `}
            </IonText>
          </div>
          {getUserId()?.toString() ===
            transmissionNote?.attributes?.author?.data?.id && (
            <IonImg
              src="/assets/images/system-user.svg"
              alt="Transmission user"
              className="transmission-user"
              style={{ width: 26, height: 26 }}
              onClick={() =>
                handleClickView(
                  transmissionNote?.id || '',
                  transmissionNote?.attributes?.isNew || false,
                  transmissionNote?.attributes?.author?.data?.id || '',
                )
              }
            />
          )}{' '}
          {transmissionNote &&
            transmissionNote.attributes?.isNew &&
            transmissionNote?.attributes?.author &&
            transmissionNote?.attributes?.author?.data &&
            transmissionNote?.attributes?.author?.data.id &&
            transmissionNote?.attributes?.author?.data?.id.toString() !==
              getUserId()?.toString() && <span className="chart-vert">{}</span>}
        </div>
      </IonItem>
    </>
  )
}

export default TransmissionListItem
