import { graphql } from 'src/models/gql';

export const CREATE_USAGER_MUTATION = graphql(/* GraphQL */ `
  mutation createUsager($data: UsagerInput!) {
    createUsager(data: $data) {
      data {
        id
      }
    }
  }
`);

export const UPDATE_USAGER_MUTATION = graphql(/* GraphQL */ `
  mutation updateUsager($id: ID!, $data: UsagerInput!) {
    updateUsager(id: $id, data: $data) {
      data {
        id,
        attributes {
          firstname
          lastname
          birthDate
          age
          chief
          nationality
          phone
          email
          addressLine
          addressCity
          addressCountry
          addressZipcode
          sexe
          resources {data {id}}
          professionalSituation {data {id}}
          resourcesPrice
          famillyCode,
          resourcesPrice,
          familyStatus {data {id}}
          positionAdministrative {data {id}}
        }
      }
    }
  }
`);

export const LIST_USAGERS = graphql(/* GraphQL */ `
  query listUsagers(
    $filters: UsagerFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    usagers(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          firstname
          lastname
          birthDate
          age
          phone
          addressLine
          addressCity
          sexe
          addressLat
          addressLng
          famillyCode
          createdAt
          updatedAt
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`);

export const FETCH_ONE_USAGER = graphql(/* GraphQL */ `
  query fetchUsagerById($id: ID!) {
    usager(id: $id) {
      data {
        id
        attributes {
          firstname
          lastname
          birthDate
          age
          chief
          nationality
          phone
          email
          addressLine
          addressCity
          addressCountry
          addressZipcode
          sexe
          resources {data {id}}
          professionalSituation {data {id}}
          resourcesPrice
          famillyCode
          resourcesPrice
          familyStatus {
            data {
              id
            }
          }
          positionAdministrative {
            data {
              id
            }
          }
        }
      }
    }
  }
`);

export const USAGER_HISTORY_FIELD = graphql(/* GraphQL */ `
  query fieldHistories(
    $filters: FieldHistoryFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    fieldHistories(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          date
          value
          field
          usager {
            data {
              id
              attributes {
                email
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`);

export const UPDATE_USAGER_FIELD_HISTORY = graphql(/* GraphQL */ `
  mutation updateFieldHistory($id: ID!, $data: FieldHistoryInput!) {
    updateFieldHistory(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`);
