import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonModal,
  IonRow,
  IonText,
  useIonToast,
} from '@ionic/react'
import { add, remove } from 'ionicons/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { setUsagerUnknown } from 'src/stores/slices/ficherActionsSlices'
import store from 'src/stores/store'
import './ConfirmModal.css'

export interface IConfirmModalQuantityProps {
  isOpen: boolean
  title?: string
  text?: string
  okText?: string
  cancelText?: string
  idAction?: string
  name?: string
  icon?: string
  onCancel: () => void
}

const ConfirmModalQuantity: React.FC<IConfirmModalQuantityProps> = ({
  isOpen,
  text,
  title,
  okText,
  cancelText,
  onCancel,
  icon,
  idAction,
  name,
}) => {
  const [showModal, setShowModal] = useState(isOpen)
  const [count, setCount] = useState(0)

  const increment = (): void => {
    setCount(count + 1)
  }

  const decrement = (): void => {
    if (count > 0) {
      setCount(count - 1)
    }
  }

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen, setShowModal])

  const handleCancel = (): void => {
    setShowModal(false)
    onCancel()
    setCount(0)
  }

  const [present] = useIonToast()

  const handleQuantityChange = (event: any): void => {
    if (event.target.value === '') {
      setCount(parseInt('0', 10))
    }
    setCount(parseInt(event.target.value, 10))
  }

  const presentToast = useCallback(() => {
    present({
      message: "Nous vous invitons à ajouter le nombre d'usager inconnu.",
      duration: 6000,
      position: 'bottom',
    })
  }, [present])

  const updateUsagerUnkown = (): void => {
    store.dispatch(setUsagerUnknown(count))
    setShowModal(false)
  }
  return (
    <IonModal
      className="confirm_modal modal-qty"
      mode="ios"
      isOpen={showModal}
      initialBreakpoint={0.45}
      onDidDismiss={() => handleCancel()}
      backdropDismiss={false}
      breakpoints={[0.4, 0.8]}
    >
      <IonGrid className="ion-padding">
        <IonRow>
          <IonCol>
            {icon}
            {title && (
              <IonText>
                <h4 className="qty-title">{title}</h4>
              </IonText>
            )}
            {text && (
              <IonText>
                <p>{text}</p>
              </IonText>
            )}
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <div className="qty-buttons">
              <div className="minus">
                <IonButton
                  onClick={decrement}
                  disabled={count === 0}
                  className="remove"
                >
                  <IonIcon icon={remove} />
                </IonButton>
              </div>

              <IonInput
                className="count"
                type="number"
                value={count || 0}
                onInput={handleQuantityChange}
                style={{ minWidth: `${count.toString().length * 26}px` }}
              />

              <div className="minus">
                <IonButton onClick={increment} className="add">
                  <IonIcon icon={add} />
                </IonButton>
              </div>
            </div>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <IonButton
              color="primary"
              expand="block"
              size="large"
              onClick={() => {
                if (count > 0) {
                  updateUsagerUnkown()
                } else {
                  presentToast()
                }
              }}
            >
              {okText || 'Oui'}
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-no-padding">
            <IonButton
              className="cancel"
              fill="clear"
              color="tertiary"
              expand="block"
              onClick={() => handleCancel()}
            >
              {cancelText || 'Annuler'}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  )
}

export default ConfirmModalQuantity
