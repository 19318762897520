import { graphql } from 'src/models/gql';


export const GET_CGU = graphql(/* GraphQL */ `
  query cgu {
    cgu {
      data {
        id
        attributes {
          file {
            data {
              id 
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`);