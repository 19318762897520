import { useEffect } from 'react'
import Usagers from 'src/components/Usagers/Usagers'
import { UsagerEntity } from 'src/models/gql/graphql'

interface UsagerFileProps {
  detailUsager: UsagerEntity|null|undefined
  viewMode?: boolean
  onReload?: () => void
}

const UsagerView: React.FC<UsagerFileProps> = ({
  detailUsager,
  viewMode,
  onReload
}) => {

  return (
    <div className="edit-usager">
      {detailUsager && <Usagers detailUsager={detailUsager} viewMode={viewMode} onReload={onReload}/>}
    </div>
  )
}

export default UsagerView
