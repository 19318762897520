/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActionCategoryEntity,
  ActionTypeEntity,
  OrientationSourceEntity,
  ServiceEntity,
  UsagerEntity,
  UsersPermissionsPermissionEntity,
} from 'src/models/gql/graphql';
import {
  fetcCategoriesActionsData,
  fetchOrientationData,
  fetchSelectCategoriesActions,
  fetchServiceData,
  fetchUsagers,
  getServicesUserSocialConnected,
  getUsagerDefaultSelected,
} from '../thunks/fiche-actions-thunks';

export interface MyUsersPermissionsPermissionEntity
  extends UsersPermissionsPermissionEntity {
  services: {
    data: {
      id: number;
    };
  };
}

interface IUsager {
  usagers: {
    data?: UsagerEntity[];
  };
}
interface IUserPermission {
  usersPermissionsUser: {
    data?: UsersPermissionsPermissionEntity;
  };
}

export interface ICategories {
  actionCategories: {
    data?: ActionCategoryEntity[];
  };
}

export interface UpdateFilter {
  field: string;
  value: string;
}

export interface StepThreeUpdate {
  id: number | string;
  usager: UsagerEntity;
}

interface ISourceSetup {
  orientationSources: {
    data?: OrientationSourceEntity[];
  };
}
interface IServiceSetup {
  services: {
    data?: ServiceEntity[];
  };
}
interface IActionTypeSelected {
  actionTypes: {
    data?: ActionTypeEntity[];
  };
}
interface StepOne {
  service?: string;
  orientation?: string;
  description?: string;
}

export type StepTwoAction = {
  id: number | string;
  quantity: number;
  name: string;
};

interface ActionsState {
  currentStep: number;
  nbUnkown: number;
  nbUnknownUsagers: number;
  haveUsager: boolean;
  isUsagerDefault: boolean;
  isUnknownUser: boolean;
  actionTypeSelected: IActionTypeSelected;
  selectActionType: boolean;
  serviceUserConnected: IUserPermission;
  usagersList: IUsager;
  serviceList: IServiceSetup;
  orientationList: ISourceSetup;
  actionsList: ICategories;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  step_one: StepOne;
  step_two: StepTwoAction[];
  step_three: UsagerEntity[];
  filters: {
    created_date: string;
    usagerFirstName: string;
    usagerLastName: string;
    usagerLocalisation: string;
    usagerSituationFamiliale: string;
    isUnknown: string;
    services: string;
    categories: string;
    actions: string;
    createdBy: string;
  };
}

const initialState: ActionsState = {
  currentStep: 1,
  haveUsager: false,
  isUnknownUser: false,
  isUsagerDefault: false,
  selectActionType: false,
  serviceUserConnected: { usersPermissionsUser: { data: {} } },
  nbUnkown: 0,
  nbUnknownUsagers: 0,
  actionTypeSelected: { actionTypes: { data: [] } },
  serviceList: { services: { data: [] } },
  orientationList: { orientationSources: { data: [] } },
  usagersList: { usagers: { data: [] } },
  actionsList: { actionCategories: { data: [] } },
  loading: 'idle',
  step_one: {
    service: '',
    orientation: '',
    description: '',
  },
  step_two: [],
  step_three: [],
  filters: {
    created_date: '',
    usagerFirstName: '',
    usagerLastName: '',
    usagerLocalisation: '',
    usagerSituationFamiliale: '',
    isUnknown: '',
    services: '',
    categories: '',
    actions: '',
    createdBy: '',
  },
};

const udpateUsagersList = (list: IUsager, id: string): IUsager => {
  const response = list.usagers?.data?.filter((res) => {
    return res.id !== id;
  });

  return { usagers: { data: response } } as unknown as IUsager;
};

const udpateUsagersListGlobal = (
  list: IUsager,
  stepper: UsagerEntity[]
): IUsager => {
  const arrayCheck = stepper.map((res) => {
    return res.id;
  });

  const response =
    list.usagers.data &&
    list.usagers?.data.filter((res) => {
      return res.id && !arrayCheck.includes(res.id);
    });
  return { usagers: { data: response } } as unknown as IUsager;
};

const checkIActionTypeSelected = (
  list: IActionTypeSelected,
  data: StepTwoAction[]
): IActionTypeSelected => {
  const arrayCheck = data.map((res) => {
    return res.id;
  });

  const response = list.actionTypes?.data?.filter((res) => {
    return res.id && !arrayCheck.includes(res.id);
  });

  return {
    actionTypes: { data: response },
  } as unknown as IActionTypeSelected;
};

const udpdateUsagersListInit = (
  list: IUsager,
  usager: UsagerEntity
): IUsager => {
  const response = list.usagers?.data?.map((res) => {
    return {
      ...res,
    };
  });

  return {
    usagers: {
      data: response?.concat(usager).sort((a, b) => {
        const dateA: Date | undefined = a?.attributes?.updatedAt
          ? new Date(a.attributes.updatedAt)
          : undefined;
        const dateB: Date | undefined = b?.attributes?.updatedAt
          ? new Date(b.attributes.updatedAt)
          : undefined;

        if (!dateA || !dateB) {
          return 0; // Fallback value if either date is undefined
        }

        return dateB.getTime() - dateA.getTime(); // Sort in descending order
      }),
    },
  } as unknown as IUsager;
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        currentStep: action.payload,
      };
    },

    setUsagerUnknown: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        nbUnkown: action.payload,
        nbUnknownUsagers: action.payload,
      };
    },

    resetUnknownOnly: (state) => {
      return {
        ...state,
        nbUnkown: 0,
      };
    },
    switchNbUnkown: (state) => {
      return {
        ...state,
        nbUnkown: state.nbUnknownUsagers,
      };
    },

    selectActionType: (state) => {
      return {
        ...state,
        selectActionType: !state.selectActionType,
      };
    },

    setIsUnknownUser: (state) => {
      return {
        ...state,
        isUnknownUser: !state.isUnknownUser,
        step_three: [],
        nbUnkown: 0,
        nbUnknownUsagers: 0,
      };
    },

    resetUsagerDefault: (state) => {
      return {
        ...state,
        isUsagerDefault: false,
        step_three: [],
      };
    },

    resetActionTypeSelected: (state) => {
      return {
        ...state,
        actionTypeSelected: { actionTypes: { data: [] } },
        selectActionType: false,
      };
    },

    setStepOne: (state, action: PayloadAction<StepOne>) => {
      return {
        ...state,
        step_one: action.payload,
      };
    },

    setStepTwo: (state, action: PayloadAction<StepTwoAction>) => {
      return { ...state, step_two: [...state.step_two, action.payload] };
    },

    removeStepTwo: (state, action: PayloadAction<number | string>) => {
      return {
        ...state,
        step_two: state.step_two.filter((res) => {
          return res.id !== action.payload;
        }),
      };
    },

    setStepThree: (state, action: PayloadAction<UsagerEntity>) => {
      return {
        ...state,
        step_three: [...state.step_three, action.payload],
        usagersList: udpateUsagersList(
          state.usagersList,
          action.payload.id || ''
        ),
      };
    },

    resetStepThree: (state) => {
      return {
        ...state,
        step_three: [],
      };
    },

    removeStepThree: (state, action: PayloadAction<StepThreeUpdate>) => {
      return {
        ...state,
        step_three: state.step_three.filter((res) => {
          return res.id !== action.payload.id;
        }),
        usagersList: udpdateUsagersListInit(
          state.usagersList,
          action.payload.usager
        ),
      };
    },

    setActionsList: (state, action: PayloadAction<ICategories>) => {
      return {
        ...state,
        actionsList: action.payload,
      };
    },

    updateFilter: (state, action: PayloadAction<UpdateFilter>) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: action.payload.value,
        },
      };
    },

    setLoading: (state) => {
      return {
        ...state,
        loading: 'pending',
      };
    },

    setLoadingSucceeded: (state) => {
      return {
        ...state,
        loading: 'succeeded',
      };
    },

    resetFilters: (state) => {
      return {
        ...state,
        filters: {
          created_date: '',
          usagerFirstName: '',
          usagerLastName: '',
          usagerLocalisation: '',
          usagerSituationFamiliale: '',
          isUnknown: '',
          services: '',
          categories: '',
          actions: '',
          createdBy: '',
        },
      };
    },

    resetFicheActions: (state) => {
      return {
        ...state,
        loading: 'idle',
        step_one: {
          service: '',
          orientation: '',
          description: '',
        },
        step_two: [],
        step_three: [],
        currentStep: 1,
        serviceUserConnected: {
          usersPermissionsUser: {
            data: {},
          },
        },
        haveUsager: false,
        isUnknownUser: false,
        isUsagerDefault: false,
        selectActionType: false,
        nbUnkown: 0,
        nbUnknownUsagers: 0,
        actionTypeSelected: { actionTypes: { data: [] } },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServicesUserSocialConnected.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getServicesUserSocialConnected.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.serviceUserConnected = action.payload
          .usersPermissionsUser as unknown as IUserPermission;
      })
      .addCase(getServicesUserSocialConnected.rejected, (state) => {
        state.loading = 'failed';
      });

    builder
      .addCase(fetchUsagers.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchUsagers.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.usagersList =
          state.step_three.length === 0
            ? (action.payload.usagers as unknown as IUsager)
            : udpateUsagersListGlobal(
                action.payload.usagers as unknown as IUsager,
                state.step_three
              );
      })
      .addCase(fetchUsagers.rejected, (state) => {
        state.loading = 'failed';
      });

    builder
      .addCase(getUsagerDefaultSelected.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getUsagerDefaultSelected.fulfilled, (state, action) => {
        state.step_three = [
          ...state.step_three,
          action.payload.usager?.usager.data as unknown as UsagerEntity,
        ];
        state.isUsagerDefault = true;
      })
      .addCase(getUsagerDefaultSelected.rejected, (state) => {
        state.loading = 'failed';
      });

    builder
      .addCase(fetchServiceData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchServiceData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.serviceList = action.payload.services as unknown as IServiceSetup;
        state.step_one =
          state.serviceList.services.data?.length === 1
            ? {
                ...state.step_one,
                service: state.serviceList.services.data[0].id?.toString(),
              }
            : {
                ...state.step_one,
              };
      })
      .addCase(fetchServiceData.rejected, (state) => {
        state.loading = 'failed';
      });

    builder
      .addCase(fetchOrientationData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchOrientationData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.orientationList = action.payload
          .orientations as unknown as ISourceSetup;
      })
      .addCase(fetchOrientationData.rejected, (state) => {
        state.loading = 'failed';
      });

    builder
      .addCase(fetcCategoriesActionsData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetcCategoriesActionsData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.actionsList = action.payload
          .categoriesActions as unknown as ICategories;
      })
      .addCase(fetcCategoriesActionsData.rejected, (state) => {
        state.loading = 'failed';
      });

    builder
      .addCase(fetchSelectCategoriesActions.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchSelectCategoriesActions.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.actionTypeSelected =
          state.step_two.length === 0
            ? (action.payload.actionTypes as unknown as IActionTypeSelected)
            : checkIActionTypeSelected(
                action.payload.actionTypes as unknown as IActionTypeSelected,
                state.step_two
              );
        state.selectActionType = true;
      })
      .addCase(fetchSelectCategoriesActions.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const {
  setCurrentStep,
  setStepOne,
  setStepTwo,
  resetFicheActions,
  resetFilters,
  setActionsList,
  selectActionType,
  setIsUnknownUser,
  resetActionTypeSelected,
  setLoading,
  setLoadingSucceeded,
  setStepThree,
  removeStepThree,
  removeStepTwo,
  resetUsagerDefault,
  setUsagerUnknown,
  resetUnknownOnly,
  switchNbUnkown,
  updateFilter,
  resetStepThree,
} = actionsSlice.actions;
export default actionsSlice.reducer;
