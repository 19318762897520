/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from 'react';
import { IonModal, IonNav } from '@ionic/react';
import styled from 'styled-components';
import ExportModalStep1 from './Step1';
import ExportModalStep2 from './Step2';
import ExportModalNoData from './NoData';


export interface IExportModalProps {
  isOpen: boolean
  onCancel?: () => void;
}

const ExportModal: React.FC<IExportModalProps> = ({
  isOpen,
  onCancel,
}) => {
  const ref = useRef<any>();
  const handleCancel = () => {
    if (onCancel) onCancel()
  }


  return (
      <ExportModalWrapper
      mode="md"
      isOpen={isOpen}
      initialBreakpoint={0.70}
      onDidDismiss={() => handleCancel()}
      breakpoints={[0.70, 1]}>
          <IonNav 
            animated={false}
            ref={ref} root={() => <ExportModalStep1 
            onCancel={(e) => {if(onCancel) onCancel()}}
            onNoData={(e) => ref.current.push(() => <ExportModalNoData 
              onSubmit={() => { if(onCancel) onCancel()}}/>, {}, null)}
            onSubmit={() => ref.current.push(() => <ExportModalStep2 
              onSubmit={() => { if(onCancel) onCancel()}}/>, {}, null)}/>} />
          
      </ExportModalWrapper>
)};
const ExportModalWrapper = styled(IonModal) `
ion-nav {
  display: contents !important; 
}
`
export default ExportModal;