/* eslint-disable no-unused-expressions */
import { useLazyQuery } from '@apollo/client'
import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonText,
  useIonViewDidEnter,
} from '@ionic/react'
import { format, subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { LoguedLayout } from 'src/components/Layouts'
import { ExportModal } from 'src/components/Modals'
import { ME_QUERY } from 'src/graphql/auth.graphql'
import { LIST_TRANSMISSION_NOTE } from 'src/graphql/transmissions-types.graphql'
import useToast from 'src/hooks/toast'
import { TransmissionNoteEntity } from 'src/models/gql/graphql'
import { updateFilter } from 'src/stores/slices/ficherActionsSlices'
import { StorageUtils, getOrgId, getUserId, isAdmin } from 'src/utils/'
import { formatingDateText } from 'src/utils/formatting'
import styled from 'styled-components'
import './Home.css'

const Home: React.FC = () => {
  const [exportOpened, openExport] = useState<boolean>(false)
  const history = useHistory()
  const [getProfilData, { data: dataProfils }] = useLazyQuery(ME_QUERY)
  const [transmissionData, setData] = useState<TransmissionNoteEntity[]>([])
  const dispatch = useDispatch()
  const handleClickUsagers = (): void => {
    history.push('/main/usagers/create')
  }
  const handleClicActions = (): void => {
    history.push('/create/action')
  }

  const clickAday = (filter: string): void => {
    const today = new Date()
    const formattedDate = format(today, 'yyyy-MM-dd')
    const yesterday = subDays(today, 1)
    const formattedYesterday = format(yesterday, 'yyyy-MM-dd')

    filter === 'today'
      ? dispatch(
          updateFilter({
            field: 'created_date',
            value: formattedDate?.toString() || '',
          }),
        )
      : dispatch(
          updateFilter({
            field: 'created_date',
            value: formattedYesterday?.toString() || '',
          }),
        )
    history.push('/main/actions')
  }

  const transmissions = (): void => {
    if (transmissionData.length > 0) {
      history.push(`/main/transmissions?isFrom=${transmissionData[0].id}`)
    }
  }

  useIonViewDidEnter(async () => {
    getActionList()
    await getProfilData()
  })

  useEffect(() => {
    if (dataProfils?.me) {
      StorageUtils.set('userData', dataProfils.me)
    }
  }, [dataProfils])

  const toasterFailed = useToast({
    text: 'Veuillez enregistrer votre URL dans la section Profils',
    position: 'bottom',
  })

  /*  const toasterProgress = useToast({
    text:
      'Vous avez un agenda paxtel pour vos profils, il est en cours de développement',
    position: 'bottom',
  }) */

  const clickAgenda = (): void => {
    if (dataProfils?.me && dataProfils.me.urlAgenda) {
      window.open(dataProfils.me.urlAgenda, '_blank')
    } else {
      toasterFailed()
    }
  }

  const [getInitData, { loading }] = useLazyQuery(LIST_TRANSMISSION_NOTE)
  const getActionList = (): void => {
    getInitData({
      notifyOnNetworkStatusChange: true,
      variables: {
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },

        pagination: {
          page: 1,
          pageSize: 1,
        },
        sort: ['createdAt:DESC'],
      },
    }).then((transmission) => {
      if (transmission.data?.transmissionNotes?.data) {
        setData(
          transmission.data?.transmissionNotes
            ?.data as TransmissionNoteEntity[],
        )
      }
    })
  }

  return (
    <HomeWrapper>
      <LoguedLayout>
        <IonContent fullscreen className="home-content">
          <div className="container wraphome-card">
            <IonCard onClick={handleClickUsagers} className="home-card">
              <IonIcon className="icon-card icon-hand" />
              <IonCardHeader>
                <IonIcon className="icon-usager" />
              </IonCardHeader>
              <IonCardContent>Créer un nouvel usager</IonCardContent>
            </IonCard>
            <IonCard onClick={handleClicActions} className="home-card">
              <IonIcon className="icon-card icon-fiche" />
              <IonCardHeader>
                <IonIcon className="icon-flag" />
              </IonCardHeader>
              <IonCardContent className="orange">
                Créer des <br /> fiches actions
              </IonCardContent>
            </IonCard>
          </div>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol className="tb-padding">
                <IonItem>
                  <IonIcon className="picto-flag" />
                  <h3 className="title-color">Mes fiches actions </h3>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow className="wrap-hier">
              <IonCol
                size="6"
                className="tb-padding"
                onClick={() => {
                  clickAday('yesterday')
                }}
              >
                <IonItem>
                  <IonButton className="btn-hier">
                    <IonText className="text-hier">Hier</IonText>
                  </IonButton>
                </IonItem>
              </IonCol>
              <IonCol size="6" className="tb-padding">
                <IonItem>
                  <IonButton
                    className="btn-ajd"
                    onClick={() => {
                      clickAday('today')
                    }}
                  >
                    <IonText className="text-hier">Aujourd’hui</IonText>
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow
              className="ion-justify-content-between"
              onClick={transmissions}
            >
              <IonCol className="tb-padding">
                <IonItem>
                  <h3 className="title-color">La dernière note transmission</h3>
                </IonItem>
              </IonCol>
              <IonCol size="2" className="tb-padding">
                <IonItem className="ion-text-end">
                  <IonIcon className="arrow-right" />
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCard className="transmission-card">
                <IonCardContent onClick={transmissions}>
                  <IonText class="transmission--desc">
                    <p>
                      {!loading &&
                      transmissionData.length > 0 &&
                      transmissionData[0]?.attributes?.description
                        ? `${transmissionData[0]?.attributes?.description.slice(
                            0,
                            150,
                          )}${
                            transmissionData[0]?.attributes?.description
                              .length > 123
                              ? ' ...'
                              : ''
                          }`
                        : !loading &&
                          "Vous n'avez pas une dernière note de transmission"}
                    </p>
                  </IonText>

                  <IonText class="transmission--user">
                    <p>
                      <span className="date">
                        {' '}
                        {transmissionData &&
                          transmissionData.length > 0 &&
                          formatingDateText(
                            transmissionData[0]?.attributes?.createdAt,
                          )}
                      </span>
                      {'  '}
                      {transmissionData && transmissionData.length > 0 && (
                        <span className="names">
                          {getUserId()?.toString() ===
                          transmissionData[0]?.attributes?.author?.data?.id
                            ? ' Moi'
                            : ` ${
                                transmissionData[0]?.attributes?.author?.data
                                  ?.attributes?.firstname
                              } ${
                                transmissionData[0].attributes?.author?.data
                                  ?.attributes?.lastname || ''
                              }  `}
                        </span>
                      )}
                    </p>
                    <IonIcon className="system-user" />
                  </IonText>
                </IonCardContent>
              </IonCard>

              {dataProfils && dataProfils.me && dataProfils.me.urlAgenda ? (
                <IonCard className="agenda-card" onClick={clickAgenda}>
                  <IonCardContent>
                    <IonIcon className="icon-calender" />
                    <IonText class="agenda--desc">
                      <h3>Mon agenda</h3>
                    </IonText>
                    <IonIcon className="icon-month" />
                  </IonCardContent>
                </IonCard>
              ) : (
                ''
              )}

              {isAdmin() && (
                <IonCard
                  button
                  className="export-card cta ion-activatable ripple-parent custom-parent"
                  onClick={() => openExport(true)}
                >
                  <IonCardContent>
                    <IonAvatar>
                      <img src="/assets/icon/home-export-icon.svg" alt="" />
                    </IonAvatar>
                    <IonText>
                      <h3>Exporter</h3>
                    </IonText>
                    <IonIcon className="icon-export-right " />
                    <IonRippleEffect />
                  </IonCardContent>
                </IonCard>
              )}
              <ExportModal
                isOpen={exportOpened}
                onCancel={() => openExport(false)}
              />
              {(dataProfils &&
                dataProfils.me &&
                dataProfils.me.isAgendaPerso) ||
              (dataProfils &&
                dataProfils.me &&
                dataProfils.me.isAgendaPaxtel) ? (
                <IonCard className="agenda-card" onClick={clickAgenda}>
                  <IonCardContent>
                    <IonIcon className="icon-calender" />
                    <IonText class="agenda--desc">
                      <h3>Mon agenda</h3>
                    </IonText>
                    <IonIcon className="icon-month" />
                  </IonCardContent>
                </IonCard>
              ) : (
                ''
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
      </LoguedLayout>
    </HomeWrapper>
  )
}
const HomeWrapper = styled(IonPage)`
  ion-card.cta {
    background: #fff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: 100%;
    h3 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-top: 10px;
    }
    &.agenda-card {
      h3 {
        color: #f6ca6b;
      }
    }
    &.export-card {
      h3 {
        color: #8db2a7;
      }
    }
    ion-avatar {
      width: 54px;
      height: 54px;
    }
    ion-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 52px;
      height: 91px;
      background: url('/assets/images/icon-month.svg') no-repeat 0 0;
      margin: auto;
      &.icon-export-right {
        opacity: 0.3;
        background: url('/assets/icon/home-export-bg.svg') no-repeat 0 0 !important;
      }
    }
    &:last-of-type {
      margin-bottom: 80px;
    }
  }
`
export default Home
