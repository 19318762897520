import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonList,
  IonNote,
  IonPage,
  IonRow,
  IonText,
  useIonLoading,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ModalLayout } from 'src/components'
import { FORGOT_PASSWORD_MUTATION } from 'src/graphql/auth.graphql'
import { validateRequired, validateEmail } from 'src/utils/validations'
import './reset-password.css'

const validators: ValidatorsType = {
  email: [validateRequired, validateEmail],
}

const ResetPassword: React.FC = () => {
  const [, setSubmit] = useState<boolean>(false)
  const [errorCustom, setErrorCustom] = useState<string>()
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const history = useHistory();
  const [forgotPassword, { loading, error }] = useMutation(FORGOT_PASSWORD_MUTATION);
  

  const [presentLoading,dismissLoading] = useIonLoading();
  
  const [resetInput, setResetPasswordInput] = useState({
    email: '',
  })

  useEffect(() => {
    if (loading) {
      presentLoading();
    } else {
      dismissLoading();
    }
  }, [loading, presentLoading, dismissLoading])
  
  const emailChange = (text: string): void => {
    setResetPasswordInput({email: text })
    validate();
  }
  const validate = (): boolean => {
  
    const value = resetInput.email;
    setIsValid(false);

    if (validators.email && validators.email.length > 0) {
      // we need to validate
      const isValidInput = validators.email.every((func) => {
        return func(value);
      })
      if (isValidInput) {
        setIsValid(true)
        return true;
      } 
      setIsValid(false)
      return false;
    }
    setIsValid(true)
    return true;
    
  };

  const clickReset = async (): Promise<void> => {
    const isValidInput = validate();
    setIsTouched(true);
    if (!isValidInput) {
      return;
    }
    setSubmit(true)
    setErrorCustom(undefined);
    const {email} = resetInput;
    try {
      const {data}= await forgotPassword({ variables: {email } })
      if (data) {
        if (data.forgotPassword && data.forgotPassword.ok) {
          // email envoyé
          // setSuccess(true);
          history.push('/reset-password-success')
          return
        }
       // setErrorCustom('Une erreur est survenue.');
        // if dont have organization
      }
    } catch (e) {
      setSubmit(false)
      // eslint-disable-next-line no-console
      console.error('error ', e);
      throw e;
    }
  }

  return (
    <IonPage>
      <ModalLayout
        onBack={() => history.goBack()}
        title="Mot de passe oublié ?"
      >
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol>
              <IonList lines="none">
                <IonItem>
                  <IonInput
                    className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`}
                    type="text"
                    label="Adresse email"
                    labelPlacement="stacked"
                    value={resetInput.email}
                    errorText={!isValid ? 'Veuillez bien vérifier le champ' : undefined }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onIonInput={(e: any) => emailChange(e?.target?.value)}
                    onIonBlur={() => setIsTouched(true) }
                  />
                </IonItem>
                <IonRow>
                  <IonCol className="ion-text-center">
                      {error && <IonNote color="danger">Une erreur s'est produite. Veuillez vérifier vos identifiants!</IonNote>}
                      {errorCustom && <IonText color="danger">{errorCustom}</IonText>}
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className="ion-text-center ion-no-padding">
                    <IonButton expand="block" size="large" color="primary" onClick={() => clickReset()}>
                      Réinitialiser mon mot de passe
                    </IonButton>
                  </IonCol>
                </IonRow>
                
                <IonRow>
                  <IonCol className="ion-text-center">
                    <Link to="/login">Se connecter</Link>
                  </IonCol>
                </IonRow>
              </IonList>

            </IonCol>
          </IonRow>
        </IonGrid>
        </ModalLayout>
    </IonPage>
  )
}

export default ResetPassword
