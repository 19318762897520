import { useLazyQuery } from '@apollo/client'
import {
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  useIonLoading,
  useIonViewDidEnter,
} from '@ionic/react'
import { add } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ModalLayout } from 'src/components'
import TransmissionListItem from 'src/components/TransmissionNoteItem/TransmissionNoteItem'
import { LIST_TRANSMISSION_NOTE } from 'src/graphql/transmissions-types.graphql'
import { TransmissionNoteEntity } from 'src/models/gql/graphql'
import { getOrgId, getUserId } from 'src/utils'
import styled from 'styled-components'
import './Transmissions.css'

const Transmissions: React.FC = () => {
  const [presentLoading, dismissLoading] = useIonLoading()
  const [getInitData, { loading }] = useLazyQuery(LIST_TRANSMISSION_NOTE)

  const [transmissionData, setData] = useState<TransmissionNoteEntity[]>([])
  const [transmissionDataList, setDataList] = useState<
    TransmissionNoteEntity[]
  >([])
  const [transmissionDataView, setDataView] = useState<
    TransmissionNoteEntity[]
  >([])

  const history = useHistory()

  useIonViewDidEnter(() => {
    const queryParameters = new URLSearchParams(window.location.search)

    getActionList(queryParameters.get('isFrom') || '')
  })

  const addTr = (): void => {
    history.push('/create/transmissions')
  }

  const getActionList = (idTransmissions?: string): void => {
    getInitData({
      notifyOnNetworkStatusChange: true,
      variables: {
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        sort: ['createdAt:DESC'],
      },
    }).then((transmission) => {
      if (
        transmission.data?.transmissionNotes?.data &&
        transmission.data?.transmissionNotes?.data.length > 0
      ) {
        setData(
          getIsNew(
            transmission.data?.transmissionNotes
              ?.data as TransmissionNoteEntity[],
          ),
        )
        setDataView(
          getIsView(
            transmission.data?.transmissionNotes
              ?.data as TransmissionNoteEntity[],
          ),
        )

        setDataList(
          transmission.data?.transmissionNotes
            ?.data as TransmissionNoteEntity[],
        )

        if (idTransmissions) {
          setTimeout(() => {
            const myButton = document.getElementById(
              `transmisionNote${idTransmissions}`,
            ) as HTMLButtonElement

            if (myButton !== null) {
              myButton.click()
            }
          }, 500)
        }
      }
    })
  }

  const handleViewClick = (value?: string): void => {
    if (value) {
      const transmissionUpdate = transmissionData.filter((res) => {
        return res.id === value
      })

      setDataView((prevData) => [
        {
          ...transmissionUpdate[0],
          attributes: { ...transmissionUpdate[0].attributes, isNew: false },
        },
        ...prevData,
      ])

      setData(
        transmissionData.filter((res) => {
          return res.id !== value
        }),
      )
    }
  }

  const handleDeleteClick = (value?: string, isNew?: boolean): void => {
    if (value && isNew) {
      setData(
        transmissionData.filter((res) => {
          return res.id !== value
        }),
      )
    } else {
      setDataView(
        transmissionDataView.filter((res) => {
          return res.id !== value
        }),
      )
    }
  }

  const getIsNew = (
    transmission: TransmissionNoteEntity[],
  ): TransmissionNoteEntity[] => {
    const response = transmission.filter((res) => {
      return (
        res.attributes?.isNew &&
        res?.attributes?.author?.data?.id?.toString() !==
          getUserId()?.toString()
      )
    })

    return response as TransmissionNoteEntity[]
  }

  const getIsView = (
    transmission: TransmissionNoteEntity[],
  ): TransmissionNoteEntity[] => {
    const response = transmission.filter((res) => {
      return (
        !res.attributes?.isNew ||
        (res.attributes?.isNew &&
          res?.attributes?.author?.data?.id?.toString() ===
            getUserId()?.toString())
      )
    })

    return response as TransmissionNoteEntity[]
  }

  useEffect(() => {
    if (loading) {
      presentLoading()
    } else {
      dismissLoading()
    }
    return () => {
      dismissLoading()
    }
  }, [loading, presentLoading, dismissLoading])

  return (
    <TransmissionWrapper>
      <ModalLayout title="Notes transmissions" classNameColor="header-green">
        <IonGrid>
          <IonRow>
            {transmissionData && transmissionData.length > 0 && (
              <IonCol size="12">
                <IonText className="ion-text-center">
                  <p className="new-note">
                    <span>NOUVEAUTÉS</span>
                  </p>
                </IonText>
              </IonCol>
            )}
            <IonCol size="12">
              {transmissionData && transmissionData.length > 0 && (
                <div className="is-new">
                  {transmissionData.map((transmission) => (
                    <TransmissionListItem
                      onViewClick={handleViewClick}
                      onDeleteClick={handleDeleteClick}
                      transmissionNote={transmission as TransmissionNoteEntity}
                      key={transmission.id}
                    />
                  ))}

                  <IonFab className="ion-fab" onClick={addTr}>
                    <IonFabButton>
                      <IonIcon icon={add} />
                    </IonFabButton>
                  </IonFab>
                </div>
              )}

              <div>
                {transmissionDataView &&
                  transmissionDataView.length > 0 &&
                  transmissionDataView.map((transmission) => (
                    <TransmissionListItem
                      onDeleteClick={handleDeleteClick}
                      transmissionNote={transmission as TransmissionNoteEntity}
                      onViewClick={handleViewClick}
                      key={transmission.id}
                    />
                  ))}

                <IonFab className="ion-fab" onClick={addTr}>
                  <IonFabButton>
                    <IonIcon icon={add} />
                  </IonFabButton>
                </IonFab>
              </div>

              {!loading &&
                transmissionDataList &&
                transmissionDataList.length === 0 && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                      Vous n'avez pas de note transmissions
                    </IonCol>
                  </IonRow>
                )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </ModalLayout>
    </TransmissionWrapper>
  )
}

const TransmissionWrapper = styled(IonPage)`
  .usagers-segments {
    position: fixed;
    width: 100%;
    z-index: 9999;
    right: 0;
    top: 104px;
    background: var(--ion-color-main-background);
    padding: 15px 0 10px;
    border-radius: 24px 24px 0 0;
  }
  .usagers-segments-content {
    margin-top: 40px;
  }
`
export default Transmissions
