import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

const Cgu: React.FC = () => {
  return (
    <IonPage>
      <IonHeader >
        <IonToolbar >
          <IonTitle>Conditions générales d'utilisations</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent >
      <IonGrid>
        <IonRow>
          <IonCol>
          <p>
    Hank. pex kel an i manAmet, consectetur adipiscing elit. Cras sed erat vel eros facilisis imperdiet ac ornare eros. Cras dui risus, elementum ut faucibus eget, sagittis ac ante. Nullam ac bibendum justo. Cras ornare lobortis elit, et fermentum nisi congue ullamcorper. Curabitur commodo magna elit, in feugiat risus cursus non. Vivamus bibendum felis quis ipsum posuere vehicula. In tristique sem vel magna pretium, id mattis enim ultrices. Proin et odio libero. Praesent ipsum nibh, accumsan sed blandit a, feugiat eu sem. Quisque egestas massa nec ligula tempor, in auctor arcu volutpat. Praesent vitae sagittis erat. Vivamus ut lacus nec ligula hendrerit interdum. Donec fermentum lectus ac elit ultrices, quis maximus diam interdum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

Mauris id metus at erat lobortis sodales eget nec lectus. Nulla libero turpis, finibus nec enim quis, pulvinar tincidunt turpis. Integer pulvinar finibus diam, et maximus eros porta at. Praesent maximus erat elit, at efficitur nunc scelerisque sit amet. Morbi eget imperdiet ante. Etiam tempor nisl felis, non aliquam mauris eleifend tristique. Morbi porttitor facilisis mauris rhoncus vulputate. Aenean gravida vitae urna non laoreet. Suspendisse potenti. In ornare efficitur enim. Phasellus maximus egestas elit ac bibendum. In fringilla eleifend tellus, quis condimentum turpis luctus at. Morbi condimentum mattis egestas. Nulla porta elementum neque.

Suspendisse a blandit metus, at placerat odio. Nulla facilisi. Donec lobortis accumsan tincidunt. Ut nulla felis, aliquet ac vulputate sit amet, tincidunt sit amet tortor. Nunc non augue imperdiet quam facilisis fermentum. Nunc venenatis gravida justo, vitae semper erat pulvinar ac. Nulla varius justo a orci vehicula porttitor.

Nunc sagittis consectetur velit bibendum iaculis. Proin nec pulvinar felis, non laoreet tortor. Nullam neque dolor, pharetra ac tincidunt id, euismod at neque. Vestibulum pretium nunc quis felis ultricies, id placerat diam dapibus. Integer quis aliquet neque. Pellentesque facilisis feugiat erat, eget ultrices felis sagittis ut. Nullam sit amet ultrices augue, quis aliquet augue. Donec pharetra vel leo eu posuere. Aenean luctus urna mauris, imperdiet rhoncus neque dignissim ac. Suspendisse quis mi quam. In feugiat lectus vel mi ullamcorper, aliquam vehicula turpis sodales.

Maecenas nisl metus, sodales et viverra eget, luctus vitae metus. Etiam imperdiet odio non mauris aliquet, id hendrerit erat ultricies. Nullam leo nulla, tincidunt non est suscipit, rutrum bibendum sem. Phasellus placerat, massa in rhoncus tincidunt, velit enim ultricies arcu, id ultricies lorem ligula eget arcu. Donec scelerisque lectus nec neque pulvinar efficitur. Integer id tellus vitae mauris cursus vehicula. Aliquam in lectus sed leo auctor pharetra. Nunc rutrum convallis diam ac accumsan. Sed tincidunt mauris vitae pretium finibus.
    </p>
          </IonCol>
        </IonRow>
      </IonGrid>
    
      </IonContent>
    </IonPage>
  )
}

export default Cgu
