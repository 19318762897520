import {
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSearchbar,
} from '@ionic/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  IonSearchbarCustomEvent,
  SearchbarChangeEventDetail,
} from '@ionic/core'
import { add } from 'ionicons/icons'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import UsagerListItem from 'src/components/UsagerListItem/UsagerListItem'
import { UsagerEntity } from 'src/models/gql/graphql'
import styled from 'styled-components'
import './Usagers-list.css'

interface UsagerListProps {
  usagersList: UsagerEntity[]
  onSearch: (term?: string) => unknown
  search?: string
}

const UsagersList: React.FC<UsagerListProps> = ({
  usagersList,
  onSearch,
  search,
}) => {
  const [usagers, setUsagers] = useState<UsagerEntity[]>([])

  useEffect(() => {
    setUsagers(usagersList)
  }, [usagersList])

  const handleSearchClick = (
    event: IonSearchbarCustomEvent<SearchbarChangeEventDetail>,
  ) => {
    // fonction à appeler lors du clic sur l'icône de recherche
    if (onSearch) onSearch(event.detail.value || '')
    event.preventDefault()
  }

  const history = useHistory()

  const clear = (event: IonSearchbarCustomEvent<void>) => {
    if (onSearch) onSearch()
    event.preventDefault()
  }

  const handleClickUsagers = (): void => {
    history.push('/main/usagers/create')
  }

  const searchRef = useRef(null)
  return (
    <UsagersListWrapper>
      <IonRow>
        <IonCol
          className="ion-no-padding"
          style={{ paddingTop: 50, paddingLeft: 10, paddingRight: 10 }}
        >
          <div>
            <IonLabel className="label-select">Rechercher des usagers</IonLabel>
          </div>
          <IonSearchbar
            enterkeyhint="search"
            showClearButton="always"
            type="search"
            mode="md"
            placeholder="Prénom, nom, téléphone, lieu, code famille"
            inputmode="search"
            // onKeyUp={(e: any) => { if(e.key === "Enter") handleSearchClick(e) }}
            ref={searchRef}
            value={search}
            debounce={700}
            onIonChange={(event) => handleSearchClick(event)}
            onIonClear={(e) => clear(e)}
            className="searchbar-has-focus"
          />
        </IonCol>
      </IonRow>

      {usagers?.length > 0 &&
        usagers.map((usager) => (
          <UsagerListItem usager={usager} key={usager.id} />
        ))}
      <IonFab onClick={handleClickUsagers} className="ion-fab">
        <IonFabButton>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    </UsagersListWrapper>
  )
}
const UsagersListWrapper = styled(IonGrid)`
  ion-searchbar {
    // ion-icon {
    //   right: 16px !important;
    //   left: auto !important;
    // }
    .searchbar-input {
      --padding-start: 5px;
    }
  }
`
export default UsagersList
