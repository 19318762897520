export default class StorageUtils {
   static prefix = process.env.REACT_APP_LOCALSTORAGE_PREFIX || 'app_';

   static get = (key: string): string | null => {
      return localStorage.getItem(`${StorageUtils.prefix}${key}`);
   }

   static set = (key: string, value?: object|string|null): unknown => {
      if (typeof value === "object") {
         // eslint-disable-next-line no-param-reassign
         value = JSON.stringify(value);
      }
      if (typeof value === 'undefined' || value === null || value === 'null') {
         return localStorage.removeItem(`${StorageUtils.prefix}${key}`);
      }
      return localStorage.setItem(`${StorageUtils.prefix}${key}`, value);
   }

   static clear = (): void => {
      return localStorage.clear();
   }
}
