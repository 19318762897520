import {IonIcon } from '@ionic/react'
import { informationCircleOutline } from 'ionicons/icons';
import { FC } from 'react'
import styled from 'styled-components';


export interface IInfoCard {
  children: any
}

const NoteListItem: FC<IInfoCard> = ({
  children,
}: IInfoCard) => {


  return  <InfoCardWrapper className="history-card">
    <div className="icon-container">
      <IonIcon icon={informationCircleOutline} />
    </div>
    <div className="text-container">
      {children}
    </div>
  </InfoCardWrapper>
}
const InfoCardWrapper = styled('div') `

display: flex;
flex-direction: row;
align-items: center;
padding: 8px;
gap: 12px;

background: #FFFFFF;
border: 1px solid #EDEDED;
border-radius: 8px;

flex: none;
order: 1;
flex-grow: 0;
div.icon-container {
  ion-icon {
    color: #F6CA6B;
    font-size: 40px;
  }
}
div.text-container {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #374151;

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    li {

    }
  }
}

`
export default NoteListItem
