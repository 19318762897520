// eslint-disable-next-line import/no-extraneous-dependencies
import { useLazyQuery, useMutation } from '@apollo/client'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
  IonTextarea,
  useIonLoading,
  useIonViewDidEnter,
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  CREATE_HEALTH_NOTE_MUTATION,
  LIST_HEALTH_NOTES,
  UPDATE_HEALTH_NOTE_MUTATION,
} from 'src/graphql/usager-health-notes.graphql'
import { UsagerHealthNoteEntity } from 'src/models/gql/graphql'
import { formatDate, getUserId } from 'src/utils'
import { validateRequired } from 'src/utils/validations'
import styled from 'styled-components'

const validators: ValidatorsType = {
  description: [validateRequired],
}
const validatorsErros = {
  description: ['Le champ description est obligatoire'],
}
const UsagersHealth: React.FC = () => {
  const [getHealtNotes, { loading, data }] = useLazyQuery(LIST_HEALTH_NOTES)
  const [createHealthNote, { loading: loadingCreate }] = useMutation(
    CREATE_HEALTH_NOTE_MUTATION,
  )
  const [updateHealthNote, { loading: loadingUpdate }] = useMutation(
    UPDATE_HEALTH_NOTE_MUTATION,
  )
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [daty, setDaty] = useState<string>('')
  const [notes, setNotes] = useState<UsagerHealthNoteEntity[] | undefined>()
  const history = useHistory()

  const { id } = useParams<{ id: string }>()
  const [isValid, setIsValid] = useState<HealthNoteFormValidation>({})
  const [isTouched, setIsTouched] = useState<NoteFormValidation>({})
  const [formValues, setFormValues] = useState<HealthNoteForm>({
    description: '',
  })
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  useIonViewDidEnter(async () => {
    const { data: currentData } = await getHealtNotes({
      notifyOnNetworkStatusChange: true,
      variables: {
        filters: {
          usager: { id: { eq: id } },
        },
      },
    })
    if (
      currentData?.usagerHealthNotes?.data &&
      currentData?.usagerHealthNotes?.data?.length > 0
    ) {
      setFormValues({
        description:
          currentData?.usagerHealthNotes?.data[0].attributes?.description || '',
      })
      setDaty(
        formatDate(
          currentData?.usagerHealthNotes?.data[0].attributes?.updatedAt,
        ),
      )
    }
    setNotes(currentData?.usagerHealthNotes?.data as UsagerHealthNoteEntity[])
  })
  const handleChange = (key: keyof HealthNoteForm) => (
    event: CustomEvent<InputChangeEventDetail>,
  ) => {
    const { value } = event.detail
    setFormValues((prevValues: any) => ({ ...prevValues, [key]: value }))
    validate(key, value)
  }
  const validate = (name: keyof HealthNoteForm, value?: any): boolean => {
    if (!value) {
      // eslint-disable-next-line no-param-reassign
      value = formValues[name]
    }
    if (validators[name] && validators[name].length > 0) {
      // we need to validate
      let falsyIndex = -1
      const isValidInput = validators[name].every((func, index) => {
        const shouldTrue = func(value)
        if (!shouldTrue) falsyIndex = index
        return shouldTrue
      })
      if (isValidInput) {
        setIsValid((prev) => ({ ...prev, [name]: true }))
        setFormErrors((prev) => ({ ...prev, [name]: undefined }))
        return true
      }
      setIsValid((prev) => ({ ...prev, [name]: false }))
      setFormErrors((prev) => ({
        ...prev,
        [name]: validatorsErros[name][falsyIndex],
      }))
      return false
    }
    setIsValid((prev) => ({ ...prev, [name]: true }))
    setFormErrors((prev) => ({ ...prev, [name]: undefined }))
    return true
  }

  const save = async (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
  ): Promise<void> => {
    const allValid = Object.keys(validators).every((name) =>
      validate(name as keyof HealthNoteForm),
    )
    setIsTouched({ description: true })
    if (!allValid) {
      return
    }
    try {
      if (!notes || !notes.length) {
        await createHealthNote({
          variables: {
            data: {
              usager: id,
              author: getUserId(),
              description: formValues.description,
            },
          },
        })
      } else {
        await updateHealthNote({
          variables: {
            id: notes[0]?.id || '',
            data: {
              description: formValues.description,
            },
          },
        })
      }
      setIsEditing(false)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error #1', e)
      throw e
    }
  }
  const [presentLoading, dismissLoading] = useIonLoading()

  useEffect(() => {
    if (loadingCreate || loading || loadingUpdate) {
      presentLoading()
    } else {
      dismissLoading()
    }

    return () => {
      dismissLoading()
    }
  }, [loadingUpdate, loading, dismissLoading, presentLoading, loadingCreate])
  return (
    <UsagersHealthwrapper className="health-wrapper">
      <>
        {isEditing && (
          <IonButton
            className="inner-back-button"
            fill="clear"
            color="medium"
            onClick={() => setIsEditing(false)}
          >
            <IonIcon slot="start" icon={arrowBack} /> Retour
          </IonButton>
        )}
        <IonRow className="ion-margin-bottom">
          <IonCol>
            {!isEditing && (
              <IonText color="primary" className="health-title ion-text-center">
                <h2 className="title-note">Note santé {daty}</h2>
              </IonText>
            )}

            {isEditing && (
              <IonText color="primary" className="health-title">
                <h2 className="update-note">Mise à jour de la note santé</h2>
              </IonText>
            )}

            {!isEditing ? (
              <IonText className="health-desc">
                <p className="label-desc" style={{ whiteSpace: 'pre-line' }}>
                  {formValues.description ||
                    'Veuillez indiquer des informations de santé de l’usager.'}
                </p>{' '}
              </IonText>
            ) : (
              <>
                <div>
                  <IonText className="label-select">Description</IonText>
                </div>

                <IonTextarea
                  className="health-textarea"
                  autoGrow
                  aria-label="Description"
                  value={formValues.description}
                  placeholder="Rédiger votre note"
                  fill="solid"
                  onIonInput={handleChange('description')}
                />
              </>
            )}

            {formErrors.description && isEditing && (
              <IonText color="danger">{formErrors.description}</IonText>
            )}
          </IonCol>
        </IonRow>
      </>

      <IonRow>
        <IonCol>
          {!isEditing ? (
            <IonButton
              className="button-large"
              expand="block"
              fill="solid"
              onClick={() => setIsEditing(true)}
            >
              Modifier la note santé
            </IonButton>
          ) : (
            <IonButton
              disabled={loadingUpdate || loadingCreate}
              className="button-large"
              expand="block"
              fill="solid"
              onClick={save}
            >
              Modifier la note santé
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </UsagersHealthwrapper>
  )
}
const UsagersHealthwrapper = styled(IonGrid)`
  ion-searchbar {
    // ion-icon {
    //   right: 16px !important;
    //   left: auto !important;
    // }
    .searchbar-input {
      --padding-start: 5px;
    }
    .searchbar-clear-button {
      right: 60px;
    }
  }
  ion-badge {
    margin-left: 5px;
    border-radius: 50%;
    padding: 2%;
  }
  ion-textarea:not(.disabled) {
    opacity: 1;
    border: 1px solid #444141;
    border-radius: 8px;
    padding: 0 8px;
    transform: none !important;
  }
`

export default UsagersHealth
