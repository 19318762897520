import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const UrlListener: React.FC<unknown> = () => {
    const history = useHistory();
    useEffect(() => {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const pathArray = event.url.split(`${process.env.REACT_APP_DEEP_LINK_SEPARATOR}`);
        const slug = pathArray.pop();
        // alert(slug);
        if (slug) {
          if (slug.includes('http')) {
            const url = new URL(slug);
            const path = url.pathname + url.search
            history.push(path);
          } else {
            history.push(slug);
          }
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return null;
  };
  
  export default UrlListener;