import React, { useEffect, useState } from 'react';
import { IonButton,  IonCol,  IonContent,  IonGrid,  IonRow, IonText } from '@ionic/react';
import styled from 'styled-components';
import { useToast } from 'src/hooks';
import ValidatedIcon from 'src/components/ValidatedIcon/ValidatedIcon';
import { getUserData } from 'src/utils';


const ExportModalStep2: React.FC<{onSubmit: (e: any) => void}> = ({
  onSubmit
}) => {
  const user = getUserData();
  return (
      <ExportModalStep2Wrapper>
          <IonGrid className="ion-padding">
          <IonRow>
            <IonCol className="ion-text-center">
              <ValidatedIcon path="./assets/icon/mail-send.svg" height={50} />
              <IonText><h4>Confirmation de l’export</h4></IonText>
             <IonText><p>L’export que vous avez paramétré a bien été envoyé par mail sur {user.email ? `l'adresse mail ${  user.email}` : 'votre adresse mail'}</p></IonText>
              </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton color="primary" expand="block" fill="outline" size="large" onClick={onSubmit}>
                Accéder à la page d’accueil 
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ExportModalStep2Wrapper>
)};
const ExportModalStep2Wrapper = styled(IonContent) `

`
export default ExportModalStep2;