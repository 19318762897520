import { graphql } from 'src/models/gql';

export const LOGIN_MUTATION = graphql(/* GraphQL */ `
  mutation LoginFn($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        firstname
        lastname
        email
        confirmed
        blocked
        healthcareWorker
        organization {
          data {
            attributes {
              companyName
              publishedAt
            }
            id
          }
        }

        pushToken
        services {
          data {
            id
            attributes {
              name
            }
          }
        }
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
`);

export const FORGOT_PASSWORD_MUTATION = graphql(/* GraphQL */ `
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`);

export const RESET_PASSWORD_MUTATION = graphql(/* GraphQL */ `
  mutation resetPassword(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
      user {
        id
        firstname
        lastname
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
        organization {
          data {
            id
            attributes {
              publishedAt
              companyName
            }
          }
        }
      }
    }
  }
`);

export const UDATED_PASSWORD_VALIDATION = graphql(/* GraphQL */ `
  mutation passwordUpdated($jwt: String!) {
    passwordUpdated(input: { jwt: $jwt }) {
      jwt
      user {
        id
        lastname
        firstname
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
        pushToken
        services {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
`);

export const REFRESH_TOKEN_MUTATION = graphql(/* GraphQL */ `
  mutation refreshToken($jwt: String!) {
    refreshToken(input: { jwt: $jwt }) {
      jwt
      user {
        id
        lastname
        firstname
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
`);

export const ME_QUERY = graphql(/* GraphQL */ `
  query MeQuery {
    me {
      id
      lastname
      firstname
      email
      phone
      confirmed
      blocked
      isAgendaPerso
      isAgendaPaxtel
      urlAgenda
      healthcareWorker
      pushToken
      services {
        data {
          id
          attributes {
            name
          }
        }
      }
      organization {
        data {
          id
          attributes {
            companyName
            publishedAt
          }
        }
      }
      role {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  }
`);

export const UPDATE_PROFILS = graphql(/* GraphQL */ `
  mutation updateMe(
    $data: UsersPermissionsUserInput!
  ) {
    updateMe(data: $data) {
      data {
        id
        attributes {
          blocked
          email
          firstname
          lastname
          isAgendaPerso
          healthcareWorker
          urlAgenda
          isAgendaPaxtel
          organization {
            data {
              id
              attributes {
                companyName
                publishedAt
              }
            }
          }

          pushToken
          services {
            data {
              id
              attributes {
                name
              }
            }
          }

          role {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`);

export const FETCH_ONE_USER = graphql(/* GraphQL */ `
  query fetchOneUser($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        attributes {
          services {
            data {
              id
            }
          }
        }
      }
    }
  }
`);

export const USERS_PERMISSIONS_LIST = graphql(/* GraphQL */ `
  query usersPermissionsUsers(
    $filters: UsersPermissionsUserFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    usersPermissionsUsers(
      filters: $filters
      pagination: $pagination
      sort: $sort
    ) {
      data {
        id
        attributes {
          firstname
          lastname
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`);
