import { 
  IonPage,
} from '@ionic/react'
import { useHistory } from 'react-router'
import { ModalLayout, SuccessCard, ValidatedIcon } from 'src/components'
import './reset-password-success.css'

const ResetPasswordSuccess: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage>
      <ModalLayout
        onBack={() => history.goBack()}
        title="Mot de passe oublié"
      >
        <SuccessCard 
        title="Un lien de réinitialisation vous a été envoyé par mail"
        icon={<ValidatedIcon path="./assets/icon/mail-send.svg" height={50} />}
        />
      </ModalLayout>
    </IonPage>
  )
}

export default ResetPasswordSuccess
