import { IonButton, IonImg } from "@ionic/react";
import styled from "styled-components";

interface HistoryButtonProps {
  onClick: (e: any) => any
}

const HistoryButton: React.FC<HistoryButtonProps> = ({
  onClick,
}) => {
  return  <HistoryButtonWrapper 
    fill="clear" 
    className="history_button"
    onClick={onClick}
    >
  <IonImg src="/assets/icon/clock-rotate-left.svg" /> 
  &nbsp;Consulter l’historique
  </HistoryButtonWrapper>;
}
const HistoryButtonWrapper = styled(IonButton) `
--padding-start: 0;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
margin-top: 0;
margin-bottom: 0;
`
export default HistoryButton;