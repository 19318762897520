import { combineReducers, configureStore } from '@reduxjs/toolkit';
import ficherActionsSlices from './slices/ficherActionsSlices';

const rootReducer = combineReducers({
  ficheActions: ficherActionsSlices,
});

const store = configureStore({
  reducer: rootReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export default store;
