import React, { useEffect, useState } from 'react';
import { IonButton,  IonCol,  IonGrid,  IonModal,  IonRow,  IonText } from '@ionic/react';
import './ConfirmModal.css';

export interface IConfirmModalProps {
  isOpen: boolean
  title?: string;
  text?: string;
  okText?: string;
  cancelText?: string;
  icon?: any, 
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  isOpen,
  text,
  title,
  okText,
  cancelText,
  onCancel,
  icon,
  onConfirm }) => {
    const [showModal, setShowModal] = useState(isOpen);
    useEffect(() => {
      setShowModal(isOpen)
    }, [isOpen, setShowModal])
  const handleCancel = () => {
    setShowModal(false);
    onCancel();
  }
  return (
      <IonModal
      className="confirm_modal"
      mode="ios"
      isOpen={showModal}
      initialBreakpoint={0.47}
      onDidDismiss={() => handleCancel()}
      breakpoints={[0, 0.33, 0.66, 1]}>
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol>
              {icon}
              {title && <IonText><h4>{title}</h4></IonText>}
              {text && <IonText><p>{text}</p></IonText>}
              </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton color="primary" expand="block"  size="large" onClick={() => onConfirm()}>
                {okText ||'Oui'}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton className="cancel" fill="clear" color="primary" expand="block"  onClick={() => handleCancel()}>
                {cancelText ||'Annuler'}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
)};

export default ConfirmModal;