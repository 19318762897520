import { IonRow, IonSpinner } from '@ionic/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import MarkerClusterGroup from 'react-leaflet-cluster'
import './Usagers-map.css'
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MapContainer, Marker, Popup, TileLayer, useMap, ZoomControl } from 'react-leaflet';
import { UsagerEntity } from 'src/models/gql/graphql';
import {  tileLayer } from 'src/utils';
import L, {LatLngTuple } from "leaflet";
import UsagerListItem from 'src/components/UsagerListItem/UsagerListItem';

interface UsagerMapProps {
  usagersList: UsagerEntity[]
}
interface MarkerData {
  lat: number;
  lng: number;
  usager: UsagerEntity
}
const defaultCenter: LatLngTuple = [48.864716, 2.349014];
const icon = L.icon({
  iconUrl: 'assets/images/usager-marker.png',
  iconRetinaUrl: 'assets/images/usager-marker-x2.png',
  shadowUrl: 'assets/images/marker-shadow.png',
  iconSize: [21, 27],
  iconAnchor: [12, 27],
  popupAnchor: [1, -20],
  tooltipAnchor: [16, -28],
  shadowSize: [27, 27]
})
const createClusterCustomIcon = function (cluster: any) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()} Usagers</span>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(33, 33, true),
  })
}

const MyMarkers = ({ data }: {data: MarkerData[]} ) => {
  const map = useMap();
  useEffect(() => {
    if (map && data && data.length  ) {
      const bounds = new L.LatLngBounds(data.map(i => [i.lat, i.lng]));
      map.fitBounds(bounds, {
        paddingTopLeft: [10, 10],
      });
  
    }
  }, [data, map])
  
  return <MarkerClusterGroup 
  iconCreateFunction={createClusterCustomIcon}
  maxClusterRadius={150}
  spiderfyOnMaxZoom
  showCoverageOnHover
  chunkedLoading>
  {data.map( ({ lat, lng, usager }, index: number) => (
    <Marker
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      position={{ lat, lng }}
      icon={icon}
      eventHandlers={{
        click(e) {
          const location = e.target.getLatLng();
          e.sourceTarget._icon.classList.add('marker-active');
          map.flyToBounds([location]);
        }
      }}
    >
      <Popup className="usager-popup" minWidth={360}>
        <UsagerListItem usager={usager} key={usager.id} />
      </Popup>
    </Marker>
  ))}
  </MarkerClusterGroup>
}

const UsagerMap: React.FC<UsagerMapProps> = ({
  usagersList
}: UsagerMapProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [markersData, setMarkersData] = useState<MarkerData[]>([]);
  const [center, setCenter] = useState<LatLngTuple>(defaultCenter);

  useEffect(() => {
    if (usagersList && usagersList.length) {
      const list = usagersList
      .filter(usager => usager.attributes?.addressLat && usager.attributes?.addressLng) 
      if (list && list.length) {
        if (list[0].attributes?.addressLat && list[0].attributes?.addressLng) {
          setCenter([list[0].attributes?.addressLat, list[0].attributes?.addressLng]);
        }
      }
      setMarkersData(() => list
          .map(usager => ({
            lat: usager?.attributes?.addressLat,
            lng: usager?.attributes?.addressLng,
            usager
          } as MarkerData)))
    }
  }, [usagersList, setMarkersData])


  return (
      <>
        {loading && <IonRow style={{width: '100%', height: '50vh'}} className="ion-align-items-center ion-justify-content-center">
          <IonSpinner />
        </IonRow>}
        <div className="component-wrapper">
        {!loading && center &&  
          <MapContainer
            zoom={50}
            style={{position: 'absolute'}}
            center={center}
            scrollWheelZoom={false}
            >
            {/* <ZoomControl position="topright" /> */}
            <TileLayer {...tileLayer} />
            <MyMarkers data={markersData} />
          </MapContainer>
        }
      </div>
    </>
  )
}

export default UsagerMap
