import React, { useEffect, useState } from 'react';
import { IonAvatar, IonButton,  IonCol,  IonContent,  IonFooter,  IonGrid,  IonHeader,  IonIcon,  IonImg,  IonItem,  IonList,  IonModal,  IonRow,  IonSearchbar,  IonText, IonToolbar } from '@ionic/react';
import { arrowBack, calendarClearOutline, closeOutline } from 'ionicons/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_USAGER_FIELD_HISTORY, UPDATE_USAGER_MUTATION, USAGER_HISTORY_FIELD } from 'src/graphql/usagers.graphql';
import AppStartDatePicker from 'src/components/AppStartDatePicker/AppStartDatePicker';
import styled from 'styled-components';
import { UsagerEntity } from 'src/models/gql/graphql';
import { isBefore } from 'date-fns/esm';
import { useToast } from 'src/hooks';
import Selection from 'src/components/Selection/Selection';
import { isAfter } from 'date-fns';


export interface IHistoryModalProps {
  isOpen: boolean
  field?: string;
  label?: string;
  usager?: UsagerEntity;
  onCancel?: (isMainFieldUpdated?: boolean) => void;
  sources?: IdNameEntity[];
}

const HistoryModal: React.FC<IHistoryModalProps> = ({
  isOpen,
  field,
  label,
  usager,
  onCancel,
  sources 
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [mainFieldUpdated, updateMainField] = useState(false);
  const [dataObject, setDataObject] = useState<any>({});
  const [loadHistory, {loading, data}] = useLazyQuery(USAGER_HISTORY_FIELD);
  const [value, setValue] = useState();
  const [histories, setHistories] = useState<any>({});
  const [
    updateFieldHistory,
    { loading: updateLoading, error },
  ] = useMutation(UPDATE_USAGER_FIELD_HISTORY)
  const [
    updateUsager,
    { loading: updateUsagerLoading, error: errorUpdate },
  ] = useMutation(UPDATE_USAGER_MUTATION)
  const errorToast = useToast({
    position: 'bottom',
    text: 'Veuillez vérifier la date que vous choisissez, elle depasse la date antérieur!'
  })

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen, setShowModal]);

  useEffect(() => {
    if (field) {
      loadHistory({
        variables: {
          filters: {
            usager: {id: {eq: usager?.id  }},
            field: {eq: field}
          },
          sort: ['createdAt:DESC'],
        }
      })
    }
    if (sources) {
      setDataObject(() => {
        const o: any = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const t of sources) {
          o[t.id] = t.name;
        }
        return o;
      })
    }
  }, [field, sources])

  useEffect(() => {
    if (data && data.fieldHistories && setHistories) {
      setHistories(() => {
        const r: any = {};
        data.fieldHistories?.data.forEach((item) => {
          r[item.id||''] = item.attributes?.date;
        });
        return r;
      });
    }
  }, [data, setHistories])

  useEffect(() => {
    if (usager && usager.id && field) {
      const val = (usager.attributes as any)[field]?.data ? 
      (usager.attributes as any)[field]?.data.id : (usager.attributes as any)[field]
      setValue(val)
    }
  }, [usager, field])

  const handleCancel = () => {
    setShowModal(false);
    if (onCancel) onCancel()
  }
 
  const handleChange = (e: any) => {
    setValue(e.detail.value);
  }
  const isAbleToModify = (id: string, date: string) => {
    // find in data
    const index = data?.fieldHistories?.data?.findIndex((i) => i.id === id)
    if (
      typeof index !== 'undefined' && index > -1 && 
      data?.fieldHistories?.data &&
      data?.fieldHistories?.data.length 
    ) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      if (index < (data?.fieldHistories?.data?.length - 1)) {
          // get previous date
          const previousId = data?.fieldHistories?.data[index + 1].id;
          const previousDate = new Date(histories[previousId!]);
          if (isBefore(new Date(date), previousDate)) {
            errorToast();
            return false;
          }
      } else {
        const previousId = data?.fieldHistories?.data[index - 1].id;
        const previousDate = new Date(histories[previousId!]);
        if (isAfter(new Date(date), previousDate)) {
          errorToast();
          return false;
        }
      }
   
      return true;
    }
    return true;
  }
  const save = async () => {
    // verify main value
    let reload = false;
    const val = (usager?.attributes as any)[field!]?.data ? 
      (usager?.attributes as any)[field!]?.data.id : (usager?.attributes as any)[field!]
    
    // verify historiques values
    let stop = false;
    data?.fieldHistories?.data.forEach(async (item) => {
      if (item?.attributes?.date !== histories[item?.id||'']) {
        // need update
        if (!isAbleToModify(item?.id||'', histories[item?.id||''])) {
          stop = true;
        }
      }
    })
    // IF NO ERRORS
    if (!stop) {
      // UPDATE main field if modified
      if (val !== value) {
        if (field) {
          try {
            await updateUsager({
              variables: {
                id: usager?.id||'',
                data: {
                  [field]: value
                }
              }
            })
            console.log('-->')
            updateMainField(true);
            reload = true;
          } catch (e) {
            console.error(e);
            throw e;
          }
        
        }
      }
      // update all modified
      if ( data?.fieldHistories?.data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of data.fieldHistories.data) {
          if (item?.attributes?.date !== histories[item?.id||'']) {
            try {
              // eslint-disable-next-line no-await-in-loop
              await updateFieldHistory({
                variables: {
                  id: item?.id||'',
                  data:{
                    date: new Date(histories[item?.id||''])
                  }
                }
              })
            } catch (e) {
              console.error(e);
              throw e;
            }
          }
        }
      }
      // now reload all data
      /* await  loadHistory({
        variables: {
          filters: {
            usager: {id: {eq: usager?.id  }},
            field: {eq: field}
          },
          sort: ['createdAt:DESC'],
        }
      }); */
      if (onCancel) onCancel(reload);
      
    }
  }
  const handleChangeDate = (id: string, e: any) => {
    setHistories((h: any) => ({...h, [id]: e.detail.value}))
  } 
  return (
      <HistoryModalWrapper
      mode="ios"
      isOpen={showModal}
      initialBreakpoint={0.90}
      onDidDismiss={() => handleCancel()}
      breakpoints={[0.90, 1]}>
        <IonContent>
          <IonRow className="ion-padding-horizontal ion-padding-top">
            <IonCol>
              <IonButton className="inner-back-button" fill="clear" color="medium"  onClick={() => onCancel && onCancel(mainFieldUpdated)}>
                <IonIcon slot="start" icon={arrowBack}/> Retour
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow   className="ion-padding-horizontal">
            <IonCol className="history__title">
              <IonImg src="/assets/icon/clock-rotate-left.svg" /> 
              <div>Historique</div>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-horizontal">
            <IonCol>
            <IonText className="label-select">{label} </IonText>
              {sources && (
                <Selection
                  name={field}
                  onChange={handleChange}
                  mode="md"
                  fill="outline"
                  value={value}
                  placeholder={label}
                  items={sources.map((i) => ({
                    id: i.id,
                    name: i.name,
                  }))}
                />
              )}
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-horizontal">
            <IonCol>
              <IonList>
                {data && data.fieldHistories?.data.map(i => <div key={i.id} className="timeline__block" >
                  <div className="timeline__img">
                    <IonAvatar style={{width: 28, height: 28}}>
                      <img alt="" src="assets/icon/user-icon.svg" />
                    </IonAvatar>
                  </div>
                  <div className="timeline__content">
                    <div>
                      {dataObject[i?.attributes?.value||'']}
                    </div>
                    <AppStartDatePicker
                      icon={{icon: calendarClearOutline, slot: "start"}}
                      name={i.id?.toString()||''}
                      displayFormat="dd MMMM yyyy"
                      label="à partir du"
                      presentation="date"
                      initialValue={histories[i.id?.toString()||'']}
                      preferWheel={false}
                      onChange={(e) =>  handleChangeDate(i.id?.toString()||'', e)}
                     />
                  </div>
                </div>)}
              </IonList>
            </IonCol>
          </IonRow>
          <IonFooter>
            <IonToolbar className="ion-no-border">
              <IonButton
                disabled={loading ||updateLoading ||updateUsagerLoading}
                onClick={save}
                expand="block"
                size="large"
                className="ion-rounded"
               type="button" color="primary">Enregistrer</IonButton>
            </IonToolbar>
          </IonFooter>
          </IonContent>
          
      </HistoryModalWrapper>
)};
const HistoryModalWrapper = styled(IonModal) `
.history__title {
  align-items: center;
  display: flex;
  --padding-start: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  ion-img {
    height: 28px;
    width: 28px;
    margin-right: 10px;
  }
}
ion-list  {
  div.timeline__block {
    display: flex;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    &::before {
      content: '';
      position: absolute;
      left: 24px;
      height: 100%;
      top: 0;
      width: 1px;
      background: black;
    }
    div.timeline__img {
      border-top: 12px solid white;
      border-bottom: 12px solid white;
      margin-left: 10px;
      z-index: 999;
      background: white;
    }
    div.timeline__content {
      color: #374151;
      flex: 1;
      display: flex;
      align-items: center;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 165%;
      margin-left: 7px;
    }
  }
}
ion-footer {
  ion-toolbar {
    --border-width: 0;
    --background-color: transparent !important;
  }
  position: fixed;
  bottom: 100px;
}
`
export default HistoryModal;