/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLazyQuery } from '@apollo/client'
import { IonSearchbarCustomEvent } from '@ionic/core'
import {
  IonBadge,
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonSearchbar,
  SearchbarChangeEventDetail,
  useIonViewDidEnter,
} from '@ionic/react'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { NoteListItem } from 'src/components'
import { LIST_NOTES } from 'src/graphql/usager-notes.graphql'
import { UsagerNoteEntity } from 'src/models/gql/graphql'
import styled from 'styled-components'

const UsagersNotes: React.FC = () => {
  const [search, setSearch] = useState<string>()
  const [getNotes, { loading, data }] = useLazyQuery(LIST_NOTES)
  const [notes, setNotes] = useState<UsagerNoteEntity[] | undefined>()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const handleSearchClick = (
    event: IonSearchbarCustomEvent<SearchbarChangeEventDetail>,
  ) => {
    // fonction à appeler lors du clic sur l'icône de recherche
    getData(event.detail.value || '')
    event.preventDefault()
  }
  const clear = (event: IonSearchbarCustomEvent<void>) => {
    setSearch('')
    getData('')
    event.preventDefault()
  }
  useIonViewDidEnter(async () => {
    const { data: currentData } = await getNotes({
      notifyOnNetworkStatusChange: true,
      variables: {
        pagination: { limit: 100, start: 0 },
        filters: {
          usager: { id: { eq: id } },
        },
      },
    })
    setNotes(currentData?.usagerNotes?.data as UsagerNoteEntity[])
  })
  const getData = (term?: string): void => {
    setSearch(term)
    if (term) {
      return setNotes(() =>
        (data?.usagerNotes?.data as UsagerNoteEntity[]).filter((note) => {
          return (
            (note &&
              note.attributes?.noteType?.data?.attributes?.name
                ?.toLocaleLowerCase()
                .includes(term.toLocaleLowerCase())) ||
            (note &&
              note.attributes?.description
                ?.toLocaleLowerCase()
                .includes(term.toLocaleLowerCase()))
          )
        }),
      )
    }
    return setNotes(data?.usagerNotes?.data as UsagerNoteEntity[])
  }
  return (
    <UsagersNoteswrapper>
      {!loading && (
        <>
          <IonRow>
            <IonCol>
              <div className="ion-align-items-center d-flex research">
                <IonLabel className="ion-label">
                  Rechercher des notes parmis les{' '}
                </IonLabel>
                <IonBadge className="counter" color="primary">
                  {data?.usagerNotes?.data.length}
                </IonBadge>
              </div>
              <IonSearchbar
                enterkeyhint="search"
                type="search"
                mode="md"
                placeholder="Nom et description de la note"
                inputmode="search"
                // onKeyUp={(e: any) => { if(e.key === "Enter") handleSearchClick(e) }}
                value={search}
                debounce={700}
                onIonInput={(event: any) => handleSearchClick(event)}
                onIonClear={(e) => clear(e)}
              />
            </IonCol>
          </IonRow>
          {notes?.map((note: any) => (
            <NoteListItem note={note} key={note.id} usagerId={id} />
          ))}
        </>
      )}

      {!loading && notes?.length === 0 && (
        <IonRow>
          <IonCol className="ion-text-center">
            Cet usager ne possède pas de note
          </IonCol>
        </IonRow>
      )}
      <IonRow className="sticky-note">
        <IonCol>
          <IonButton
            className="button-large action-btn"
            expand="block"
            fill="solid"
            onClick={() =>
              history.replace(`/main/usagers/view/${id}/notes/create`)
            }
          >
            Créer une nouvelle note
          </IonButton>
        </IonCol>
      </IonRow>
    </UsagersNoteswrapper>
  )
}
const UsagersNoteswrapper = styled(IonGrid)`
  ion-searchbar {
    // ion-icon {
    //   right: 16px !important;
    //   left: auto !important;
    // }
    .searchbar-input {
      --padding-start: 5px;
    }
    .searchbar-clear-button {
      right: 60px;
    }
  }
  ion-badge {
    margin-left: 5px;
    border-radius: 50%;
    padding: 2%;
  }
`

export default UsagersNotes
