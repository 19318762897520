import React, { useEffect, useState } from 'react';
import { IonButton,  IonCol,  IonContent,  IonGrid,  IonHeader,  IonItem,  IonList,  IonModal,  IonRow,  IonSearchbar,  IonText, IonToolbar } from '@ionic/react';
import './SelectModal.css';
import { closeOutline } from 'ionicons/icons';

export interface IItem {
  name: string;
  id: string;
}

export interface ISelectModalProps {
  isOpen: boolean
  autocomplete: boolean
  items: IItem[];
  onSelect: (item: IItem) => void,
  onCancel: () => void
}

const SelectModal: React.FC<ISelectModalProps> = ({
  isOpen,
  autocomplete,
  items,
  onSelect,
  onCancel
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen, setShowModal]);

  useEffect(() => {
    if (items) {
      setData([...items]);
    }
  }, [items])

  const [data, setData] = useState<IItem[]>([]);

  const handleCancel = () => {
    setShowModal(false);
    onCancel()
  }
  const select = (item: IItem) => {
    onSelect(item);
  }
  const handleOnFilterChange = (term: string) => {
    setData(() => [...items].filter(i => i.name.toLocaleLowerCase()
        .includes(term.toLocaleLowerCase()))
    )
  }
  const handleFilterInput = (e: Event) => {
    if (autocomplete) {
      handleOnFilterChange((e.target as HTMLInputElement).value)
    }
  }
  return (
      <IonModal
      className="confirm_modal filter-modal"
      mode="ios"
      isOpen={showModal}
      initialBreakpoint={0.90}
      onDidDismiss={() => handleCancel()}
      breakpoints={[0.90, 1]}>
         <IonHeader translucent>
              <IonToolbar>
                  <IonSearchbar
                      className="in-modal ion-margin-top"
                      mode="ios"
                      cancelButtonIcon={closeOutline}
                      placeholder="Recherche"
                      cancelButtonText="Annuler"
                      onIonInput={(e)=> handleFilterInput(e)}
                      onIonChange={(e: any) => handleOnFilterChange(e.target.value)}
                      onIonCancel={() => handleCancel()}
                      showCancelButton="always" />
              </IonToolbar>
          </IonHeader>
        <IonContent>
          <IonRow className="ion-padding-horizontal">
            <IonCol>
              <IonList>
                {data && data.map((i, idx) => <IonItem 
                button
                detail={false}
                // eslint-disable-next-line react/no-array-index-key
                key={`${idx}${i.id}`}
                onClick={() => select(i)}>{i?.name}</IonItem>)}
              </IonList>
            </IonCol>
          </IonRow>
          </IonContent>
      </IonModal>
)};

export default SelectModal;