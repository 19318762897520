import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getServiceUserConnected,
  getSetupFicheActions,
  getSetupOrientation,
  getSetupServiceList,
  getUsagerDefault,
  getUsersList,
  selectCategories,
} from '../slices/provider/usagers/usagers.service';

type FilterType = {
  [key: string]: unknown;
};
interface Filters {
  and: FilterType[];
}

interface Pagination {
  page: number;
  pageSize: number;
}

export const fetchUsagers = createAsyncThunk(
  'usagers/fetch',
  async ({
    filters,
    pagination,
    sort,
  }: {
    filters: Filters;
    pagination: Pagination;
    sort: string[];
  }) => {
    try {
      const usagers = await getUsersList(filters, pagination, sort);
      return { usagers };
    } catch (error) {
      return { error: 'err_fetch' };
    }
  }
);

export const getUsagerDefaultSelected = createAsyncThunk(
  'usagers/selected',
  async ({ id }: { id: string | number }) => {
    try {
      const usager = await getUsagerDefault(id);
      return { usager };
    } catch (error) {
      return { error: 'err_fetch' };
    }
  }
);

export const fetchServiceData = createAsyncThunk(
  'services/fetch',
  async ({
    filters,
    pagination,
    sort,
  }: {
    filters: Filters;
    pagination: Pagination;
    sort: string[];
  }) => {
    try {
      const services = await getSetupServiceList(filters, pagination, sort);
      return { services };
    } catch (error) {
      return { error: 'err_fetch' };
    }
  }
);

export const fetchOrientationData = createAsyncThunk(
  'orientation/fetch',
  async ({
    filters,
    pagination,
    sort,
  }: {
    filters: Filters;
    pagination: Pagination;
    sort: string[];
  }) => {
    try {
      const orientations = await getSetupOrientation(filters, pagination, sort);

      return { orientations };
    } catch (error) {
      return { error: 'err_fetch' };
    }
  }
);

export const fetchSelectCategoriesActions = createAsyncThunk(
  'select-categories/fetch',
  async ({
    filters,
    pagination,
    sort,
  }: {
    filters: Filters;
    pagination: Pagination;
    sort: string[];
  }) => {
    try {
      const actionTypes = await selectCategories(filters, pagination, sort);

      return { actionTypes };
    } catch (error) {
      return { error: 'err_fetch' };
    }
  }
);

export const fetcCategoriesActionsData = createAsyncThunk(
  'categories-actions/fetch',
  async ({
    filters,
    pagination,
    sort,
  }: {
    filters: Filters;
    pagination: Pagination;
    sort: string[];
  }) => {
    try {
      const categoriesActions = await getSetupFicheActions(
        filters,
        pagination,
        sort
      );
      return { categoriesActions };
    } catch (error) {
      return { error: 'err_fetch' };
    }
  }
);

export const getServicesUserSocialConnected = createAsyncThunk(
  'services/user-connected',
  async ({ id }: { id: string | number }) => {
    try {
      const usersPermissionsUser = await getServiceUserConnected(id);
      return { usersPermissionsUser };
    } catch (error) {
      return { error: 'err_fetch' };
    }
  }
);
