import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonRow,
} from '@ionic/react'
import { ReactElement } from 'react'

export type LayoutProps = {
  title?: string
  introText?: string
  isPassword?: boolean
  children: React.ReactNode
}

export default function AuthLayout({
  title,
  introText,
  isPassword,
  children,
}: LayoutProps): ReactElement {
  return (
    <>
      {/* <IonContent className={!isPassword ? 'login' :'login-password-step'}> */}
      <IonHeader
        className={
          !isPassword
            ? 'login header-login'
            : 'login-password-step header-login'
        }
        no-border
        style={{ boxShadow: 'none !important' }}
      >
        <div className="banner">
          <IonImg
            className="banner__image"
            src="/assets/images/mask.png"
            alt="Paxtel"
          />
        </div>
        <IonGrid className="logo">
          <IonImg
            className="logo--white"
            src="/assets/images/logo-white.svg"
            alt="Logo Paxtel"
          />
          <IonImg
            className="logo--color"
            src="/assets/images/logo.svg"
            alt="Logo Paxtel"
          />
        </IonGrid>
      </IonHeader>

      <IonContent className="login-content" scrollY={false}>
        <IonGrid>
          <IonRow>
            <IonCol size="12" className="title ion-text-center">
              {title}
            </IonCol>
            <IonCol size="12" className="intro ion-text-center">
              {introText}
            </IonCol>
          </IonRow>
        </IonGrid>
        {children}
      </IonContent>

      {/* </IonContent> */}
    </>
  )
}
