import { graphql } from 'src/models/gql';


export const CREATE_HEALTH_NOTE_MUTATION = graphql(/* GraphQL */ `
  mutation createUsagerHealthNote(
    $data: UsagerHealthNoteInput!
  ) {
    createUsagerHealthNote(data: $data ) {
      data {id}
    }
}
`);

export const UPDATE_HEALTH_NOTE_MUTATION =  graphql(/* GraphQL */ `
  mutation updateUsagerHealthNote(
    $id: ID!
    $data: UsagerHealthNoteInput!
  ) {
    updateUsagerHealthNote(
      id: $id
      data: $data
    ) {
      data {
        id
      }
    }
}
`);

export const DELETE_HEALTH_NOTE_MUTATION =  graphql(/* GraphQL */ `
  mutation deleteUsagerHealthNote(
    $id: ID!
  ) {
    deleteUsagerHealthNote(
      id: $id
    ) {
      data {
        id
      }
    }
}
`);


export const LIST_HEALTH_NOTES = graphql(/* GraphQL */ `
  query listUsagerHealthNotes(
    $filters: UsagerHealthNoteFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    usagerHealthNotes(
      filters: $filters
      pagination: $pagination
      sort: $sort
    ) {
    data {
      id,
      attributes {
        createdAt
        updatedAt
        author {
          data {
            id
            attributes {
              firstname
              lastname
            }
          }
        }
        description
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
  }
`);

export const FETCH_ONE_HEALTH_NOTE = graphql(/* GraphQL */ `
  query fetchUsagerHealthNoteById($id: ID!) {
    usagerHealthNote(id: $id) {
      data {
        id
        attributes {
          author {
            data {
              id
              attributes {
                firstname
                lastname
              }
            }
          }
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`)