import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
} from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import './ModalLayout.css'

type ModalLayoutProps = {
  title: string
  classNameColor?: string
  onBack?: () => void
  children: React.ReactNode
}

const ModalLayout: React.FC<ModalLayoutProps> = ({
  title,
  onBack,
  children,
  classNameColor,
}) => {
  return (
    <>
      <IonHeader className={`header-connected ${classNameColor}`}>
        <IonToolbar color="primary" className="header-container">
          <IonButtons slot="start">
            {onBack && (
              <IonButton onClick={onBack}>
                <IonIcon slot="icon-only" icon={arrowBack} color="light" />
              </IonButton>
            )}
          </IonButtons>
          <h1 className="header-title">{title}</h1>
        </IonToolbar>
      </IonHeader>
      <IonContent className="main-content">{children}</IonContent>
    </>
  )
}

export default ModalLayout
