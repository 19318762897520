import { IonButton, IonIcon, IonInput, IonItem } from '@ionic/react'
import { caretDownOutline } from 'ionicons/icons'
import { useState } from 'react'
import styled from 'styled-components'
import SelectModal from '../Modals/SelectModal/SelectModal'

interface IItem {
  id?: string | null
  name?: string | null
}

const Selection: React.FC<any> = ({
  name,
  autocomplete = false,
  onChange,
  items,
  valueKey = 'id',
  value,
  disabled,
  ...rest
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const handleClick = (e: any) => {
    if (!disabled) setOpenModal(true)
    e.preventDefault()
  }
  return (
    <SelectionWrapper
      className={disabled ? 'disabled' : ''}
      mode="md"
      shape="round"
      key={valueKey}
    >
      <IonInput
        legacy
        disabled={disabled}
        fill="solid"
        readonly
        name={name}
        value={value && items ? items.find((i: any) => i.id === value)?.name : ''}
        onClick={(e) => {
          handleClick(e)
          e.preventDefault();
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <IonButton slot="end"  size="small" fill="clear" onClick={(e) => {
          handleClick(e)
          e.preventDefault();
        }}>
        <IonIcon icon={caretDownOutline} slot="end" size="small" />
      </IonButton>
     
      <SelectModal
        autocomplete={autocomplete}
        isOpen={openModal}
        items={items}
        onCancel={() => setOpenModal(false)}
        onSelect={(item: any) => {
          onChange({ detail: { value: item[valueKey] } })
          setOpenModal(false)
        }}
      />
    </SelectionWrapper>
  )
}

export default Selection
export const SelectionWrapper = styled(IonItem)`
  height: 52px;
  border-radius: 8px;
  border: 1px solid #5e5959;
  color: #374151;
  --highlight-height: 0;
  --highlight-color-focused: transparent;
  part[native] {
    border: none;
  }
  ion-input {
    --padding-start: 15px !important;
  }
  ion-icon {
    width: 12px;
    margin-right: 10px;
  }
  &.disabled {
    border: none;
    ion-icon {
      display: none;
    }
    ion-input {
      --padding-start: 0 !important;
    }
  }
`
