import { graphql } from 'src/models/gql';

export const GET_ADMINISTRATIVE = graphql(/* GraphQL */ `
  query administrativePositions(
    $filters: AdministrativePositionFiltersInput
    $pagination: PaginationArg
  ) {
    administrativePositions(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`);

export const GET_FAMILY = graphql(/* GraphQL */ `
  query familyStatuses(
    $filters: FamilyStatusFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    familyStatuses(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`);

export const GET_REMUNERATIONS_TYPE = graphql(/* GraphQL */ `
  query remunerationTypes(
    $filters: RemunerationTypeFiltersInput
    $pagination: PaginationArg
  ) {
    remunerationTypes(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`);

export const GET_ORIENTATIONS_LIST = graphql(/* GraphQL */ `
  query orientationSources(
    $filters: OrientationSourceFiltersInput
    $pagination: PaginationArg
  ) {
    orientationSources(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`);

export const GET_SERVICES_LIST = graphql(/* GraphQL */ `
  query services($filters: ServiceFiltersInput, $pagination: PaginationArg) {
    services(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`);

export const GET_PROFESSIONAL_SITUATIONS = graphql(/* GraphQL */ `
  query professionalSituations(
    $filters: ProfessionalSituationFiltersInput
    $pagination: PaginationArg
  ) {
    professionalSituations(filters: $filters, pagination: $pagination) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`);