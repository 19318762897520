/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonToolbar,
} from '@ionic/react'

export const SelectionTri = ({
  onDismiss,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
              Annuler
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem onClick={() => onDismiss('desc', 'confirm')}>
          Plus récentes
        </IonItem>
        <IonItem onClick={() => onDismiss('asc', 'confirm')}>
          Plus anciennes{' '}
        </IonItem>
      </IonContent>
    </IonPage>
  )
}
