import { IonPage } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { ModalLayout } from 'src/components'
import Usagers from 'src/components/Usagers/Usagers'
import './Create-usagers.css'

const CreateUsagers: React.FC = () => {
  const history = useHistory()

  const handleBack = (): void => {
    history.replace('/main/usagers')
  }

  return (
    <IonPage>
      <ModalLayout title="Création d’un usager" onBack={handleBack}>
        <Usagers isCreate />
      </ModalLayout>
    </IonPage>
  )
}

export default CreateUsagers
