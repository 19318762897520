/* eslint-disable camelcase */
import { IonCol, IonGrid, IonImg, IonRow, IonText } from '@ionic/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/stores/store'

const StepFour: React.FC = (): JSX.Element => {
  const { step_two } = useSelector((state: RootState) => state.ficheActions)
  return (
    <IonGrid className="validated ion-no-padding">
      <IonRow>
        <IonCol>
          <IonImg src="/assets/images/validated.svg" alt="Tick" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText className="title ion-text-center">
            {step_two.length}{' '}
            {step_two.length <= 1
              ? 'fiche action a été enregistrée'
              : 'fiches actions ont été enregistrées'}{' '}
          </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default StepFour
