import { IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import * as Pages from 'src/pages'
import { UrlListener } from './components'
import FilterAction from './pages/Main/Actions/FilterAction'
import CrSuccess from './pages/Main/Transmissions/create-edit/CrSuccess'
import CrTransmissions from './pages/Main/Transmissions/create-edit/CrTransmissions'
import CreateActions from './pages/Main/usagers/detail/actions/create-actions/create-actions'
import { isAuthenticated, StorageUtils } from './utils'

/**
 * Main routing structure
 * @returns - Liste of configured routes
 */
const Routes: FC = () => {
  /**
   * Implement routing protected condition here
   * call auth state from store context hooks
   */
  const renderAuth = (
    Component: JSX.Element,
    location?: Location,
  ): JSX.Element => {
    if (!isAuthenticated()) {
      if (location && location.pathname !== '/login')
        StorageUtils.set('backUrl', location)
      return <Redirect to="/login" />
    }
    return Component
  }
  const renderGuest = (Component: JSX.Element): JSX.Element => {
    if (isAuthenticated()) {
      return <Redirect to="/main/home" />
    }
    return Component
  }

  return (
    <IonReactRouter>
      <UrlListener />
      <IonRouterOutlet id="main">
        <Route
          path="/login"
          render={() => renderGuest(<Pages.Login />)}
          exact
        />
        <Route
          path="/reset-password"
          exact
          render={() => renderGuest(<Pages.ResetPassword />)}
        />
        <Route
          path="/create-password"
          render={() => renderGuest(<Pages.CreatePassword />)}
          exact
        />
        <Route
          path="/reset-password-success"
          render={() => <Pages.ResetPasswordSucces />}
          exact
        />

        <Route
          path="/:actionName/:id/actions/create"
          render={() => renderAuth(<CreateActions />)}
          exact
        />

        <Route
          path="/create/action"
          render={() => renderAuth(<CreateActions />)}
          exact
        />
        <Route
          path="/actions/filters"
          render={() => renderAuth(<FilterAction />)}
          exact
        />
        <Route
          path="/create/transmissions"
          render={() => renderAuth(<CrTransmissions />)}
          exact
        />
        <Route
          path="/udpdate/transmissions/:id"
          render={() => renderAuth(<CrTransmissions />)}
          exact
        />
        <Route
          path="/create/transmissions/success"
          render={() => renderAuth(<CrSuccess />)}
          exact
        />

        <Route path="/main" render={() => renderAuth(<Pages.MainTabs />)} />

        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  )
}

export default Routes
