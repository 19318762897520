import { useMutation, useQuery } from '@apollo/client'
import {
  IonButton,
  IonGrid,
  IonCol,
  IonInput,
  IonItem,
  IonList,
  IonRow,
  useIonLoading,
  IonText,
  IonPage,
  IonCheckbox,
} from '@ionic/react'
import { StorageUtils } from 'src/utils'
import React, { MouseEventHandler, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { RESET_PASSWORD_MUTATION, UDATED_PASSWORD_VALIDATION } from 'src/graphql/auth.graphql'
import { validateRequired } from 'src/utils/validations'
import './create-password.css'
import { useToast } from 'src/hooks'
import { AuthLayout, Cgu, ModalLayout } from 'src/components'
import { ComponentModal } from 'src/components/Modals'
import styled from 'styled-components'
import { GET_CGU } from 'src/graphql/cgu.graphql'

const validators: ValidatorsType = {
  password: [validateRequired],
  confirmPassword: [validateRequired]
}
const validatorsErros = {
  password: ['Ce champ est obligatoire'],
  confirmPassword: ['Ce champ est obligatoire'],
  cgu: ['Veuillez accepter notre CGU'],
}

const CreatePassword: React.FC = () => {
  const [isSubmitted, setSubmit] = useState<boolean>(false)
  const [openedCGU, openCGU] = useState<boolean>(false)
  const [errorPasswordMissmatch, setErrorPasswordMissmatch] = useState<boolean>(false)
  const [errorCustom, setErrorCustom] = useState<string>()
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD_MUTATION);
  const [passwordUpdated, { loading: loadingUpdated, error: errorUpdated }] = useMutation(UDATED_PASSWORD_VALIDATION);
  const {data: dataCgu} = useQuery(GET_CGU);
  const [presentLoading,dismissLoading] = useIonLoading();
  const {search} = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search)
  const code = query.get('code') ||'';
  const registering = query.get('registering') ||'';
  const LayoutComponent = registering === '1'?  AuthLayout : ModalLayout;
 
  const toast = useToast({
    position: 'bottom',
    text: 'Votre nouveau mot de passe à été validé!'
  })

  const [isTouched, setIsTouched] = useState<CreatePasswordFormValidation>({});
  const [isValid, setIsValid] = useState<CreatePasswordFormValidation>({});


  const [passwordInput, setPasswordInput] = useState<CreatePasswordForm>({
    password: '',
    confirmPassword: '',
    cgu: false,
  })
  const setInputValue = (name: CreatePasswordFormKey, ev: any): void => {
    let value: any;
    if (name === 'cgu') {
      value = ev.target.checked
    } else {
      value = (ev.target as HTMLInputElement).value;
    }
    setPasswordInput((prev: CreatePasswordForm) => ({ ...prev, [name]: value }))
    validate(name);
  }
  const validate = (name: CreatePasswordFormKey, value?: any): boolean => {
  
    if (!value) {
      // eslint-disable-next-line no-param-reassign
      value = passwordInput[name]
    }
    if (validators[name] && validators[name].length > 0) {
      // we need to validate
      let falsyIndex = -1;
      const isValidInput = validators[name].every((func, index) => {
        const shouldTrue = func(value);
        if (!shouldTrue) falsyIndex = index;
        return shouldTrue
      })
      if (isValidInput) {
        setIsValid((prev) => ({ ...prev, [name]: true }))
        setFormErrors((prev) => ({ ...prev, [name]:  undefined}))
        return true
      }
      setIsValid((prev) => ({ ...prev, [name]: false }))
      setFormErrors((prev) => ({ ...prev, [name]:  validatorsErros[name][falsyIndex]}))
      return false
    }
    setIsValid((prev) => ({ ...prev, [name]: true }))
    setFormErrors((prev) => ({ ...prev, [name]:  undefined}))
    
    return true
    
  };
  // loading
  useEffect(() => {
    if (loading||loadingUpdated) {
      presentLoading();
    } else {
      dismissLoading();
    }
    return () => {
      dismissLoading();
    }
  }, [loading,loadingUpdated, presentLoading, dismissLoading])
  


  const clickReset = async (): Promise<void> => {
    setErrorCustom(undefined);
    setErrorPasswordMissmatch(false);
    const allValid = (['password', 'confirmPassword'] as CreatePasswordFormKey[]).every(name => validate(name));
    setIsTouched({confirmPassword: true, password: true});
    if (!allValid) {
      return;
    }
    if (passwordInput.password !== passwordInput.confirmPassword) {
      setIsValid((prev) => ({...prev, confirmPassword: false}))
      setErrorPasswordMissmatch(true);
      return;
    }
    if (registering && !passwordInput.cgu) {
      setIsValid((prev) => ({...prev, cgu: false}))
      setErrorCustom('Veuillez accepter notre CGU');
      return;
    }
    setSubmit(true)
    try {
      const {confirmPassword: passwordConfirmation, password} = passwordInput;
      const {data: response} = await resetPassword({ variables: { password, passwordConfirmation, code } })
      if (response) {
        if (!response.resetPassword) {
          return;
        }
        // if dont have organization
        if (!response.resetPassword?.user?.organization?.data) {
          setErrorCustom('Vous n\'avez pas encore de structure. Veuillez nous contacter!');
          return;
        }
        // if organization not validated
        if (!response.resetPassword.user.organization?.data?.attributes?.publishedAt) {
          setErrorCustom('Votre compte a été désactivé. Merci de contacter les admins Paxtel.');
          return;
        }
        if (response?.resetPassword.jwt) {
          await passwordUpdated({variables: {jwt: response?.resetPassword.jwt}})
        }
        toast();
        history.push('/login');
        // StorageUtils.set('authToken', response?.resetPassword.jwt)
        // StorageUtils.set('userData', response?.resetPassword.user)
        setSubmit(false)
      }
    } catch (e) {
      setSubmit(false)
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    }
  }
  const openCGUFunc = (e: React.MouseEvent): any => {
    if (!dataCgu) {
      e.preventDefault();
      return;
    }
    window.open(dataCgu.cgu?.data?.attributes?.file?.data?.attributes?.url, "_blank", "noreferrer");
  }

  return (
    <CreatePasswordWrapper>
      {LayoutComponent && <LayoutComponent
        onBack={() => history.goBack()}
        title="Créer votre mot de passe"
      >
        <IonGrid> 
          <IonRow className="ion-padding-top">
            <IonCol>
              <IonList lines="none">
                <IonItem>
                  <IonInput
                    className={`${isValid.password && 'ion-valid'} ${isValid.password === false && 'ion-invalid'} ${isTouched.password && 'ion-touched'}`}
                    type="password"
                    label={registering ?
                        'Mot de passe':
                      'Nouveau mot de passe'}
                    labelPlacement="stacked"
                    placeholder=""
                    errorText={!isValid.password ? 'Ce champ est obligatoire' : ''}
                    value={passwordInput.password}
                    onIonInput={(e) => setInputValue('password', e)}
                    onIonBlur={() => setIsTouched((prev) => ({...prev, password: true})) }
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    className={`${isValid.confirmPassword && 'ion-valid'} ${isValid.confirmPassword === false && 'ion-invalid'} ${isTouched.confirmPassword && 'ion-touched'}`}
                    type="password"
                    placeholder=""
                    // eslint-disable-next-line no-nested-ternary
                    errorText={!isValid.confirmPassword ? (errorPasswordMissmatch? 'Les mots de passe ne sont pas identiques': 'Ce champ est obligatoire') : ''}
                    label={
                      registering ?
                      'Confirmation mot de passe':
                      'Confirmation du nouveau mot de passe'}
                    labelPlacement="stacked"
                    value={passwordInput.confirmPassword}
                    onIonInput={(e) => setInputValue('confirmPassword', e)}
                    onIonBlur={() => setIsTouched((prev) => ({...prev, confirmPassword: true})) }
                  />
                </IonItem>
                {registering && <div>
                  <div className="cgu-container">
                  <IonCheckbox
                    className={`${isValid.cgu && 'ion-valid'} ${isValid.cgu === false && 'ion-invalid'} ${isTouched.cgu && 'ion-touched'}`}
                    justify="start"
                    checked={passwordInput.cgu === true}
                    onIonChange={(e) => setInputValue('cgu', e)}
                    labelPlacement="end"
                  >J'accepte les&nbsp;</IonCheckbox>
                  <button type="button" onClick={openCGUFunc}>Conditions</button>
                  <button type="button" onClick={openCGUFunc}>&nbsp;générales</button>
                  <button type="button" onClick={openCGUFunc}>&nbsp;d'utilisation</button>
                </div></div>}
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow>
              <IonCol className="ion-text-center">
                  {error && !errorCustom && 
                    <IonText color="danger">
                      {error.message === 'Incorrect code provided' ?
                      'Nous sommes désolé, le lien de reinitialisation est expiré, vérifiez que vous n\'avez pas envoyé une autre de mande de reinitialisation!' :
                      `Une erreur s'est produite: ${error.message}`}
                    </IonText>}
                  {errorCustom && <IonText color="danger">{errorCustom}</IonText>}
              </IonCol>
            </IonRow>
                
          <IonRow className="ion-margin-bottom">
            <IonCol>
              <IonButton  size="large" expand="block"
                  onClick={() => clickReset()}
                >
                 {registering ? 
                  'Finaliser mon inscription' :
                  'Confirmer'}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid> 
        <ComponentModal
          isOpen={openedCGU}
          title="CGU"
          onCancel={() => openCGU(false)}
        >
          <Cgu/>
        </ComponentModal>
      </LayoutComponent>}
    </CreatePasswordWrapper>
  )
}
const CreatePasswordWrapper = styled(IonPage) `

.cgu-container {
}
ion-checkbox {
  --border-width: 1px;
  --border-radius: 0;
  --border-color:  var(--ion-color-primary);
  --size: 16px;
  .checkbox-wrapper {
    align-items: baseline !important;
  }
}
ion-checkbox::part(container) {
  border-radius: 1px;
  border: 2px solid var(--ion-color-primary);
}
button {
  &[type="button"] {
    vertical-align: top;
    text-decoration: underline;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    background: white;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Inter-regular';
    font-weight: normal;
    color: var(--ion-color-primary);
  }
}
`
export default CreatePassword
