/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import { IonButton, IonLabel, IonText, useIonLoading } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ConfirmModalUsager from 'src/components/Modals/ConfirmModal/ConfirmModalUsager'
import UsagerListItem from 'src/components/UsagerListItem/UsagerListItem'
import { setIsUnknownUser } from 'src/stores/slices/ficherActionsSlices'
import store, { RootState } from 'src/stores/store'
import { fetchUsagers } from 'src/stores/thunks/fiche-actions-thunks'
import { getOrgId } from 'src/utils/auth'
import styled from 'styled-components'

import './stepper.css'

export interface StepThreeProps {
  isSearch?: boolean
}

const StepThree: React.FC<StepThreeProps> = ({ isSearch }) => {
  const [present, dismiss] = useIonLoading()
  const [modalSuccess, showModalSuccess] = useState(false)
  const {
    usagersList,
    isUsagerDefault,
    loading,
    isUnknownUser,
    nbUnknownUsagers,
    nbUnkown,
  } = useSelector((state: RootState) => state.ficheActions)

  useEffect(() => {
    if (loading === 'pending') {
      present()
    } else {
      dismiss()
    }
    return () => {
      dismiss()
    }
  }, [dismiss, loading, present])

  useEffect(() => {
    initForm()
  }, [])

  const initForm = (): void => {
    store.dispatch(
      fetchUsagers({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },
        sort: ['updatedAt:DESC'],
      }),
    )
  }

  const { usagers } = usagersList

  const closeModal = (): void => {
    if (nbUnkown === 0) {
      store.dispatch(setIsUnknownUser())
      showModalSuccess(false)
    }
    showModalSuccess(false)
  }
  const handleClickUsagerUnknown = (): void => {
    store.dispatch(setIsUnknownUser())
    initForm()
    if (nbUnknownUsagers === 0) {
      showModalSuccess(!modalSuccess)
    }
  }

  return (
    <StepThreeWrapper>
      <div>
        <IonButton
          color="primary"
          expand="block"
          size="large"
          fill={!isUnknownUser ? 'outline' : 'solid'}
          className="btn-usager"
          disabled={isUsagerDefault}
          onClick={handleClickUsagerUnknown}
        >
          Usager(s) inconnu(s)
        </IonButton>
      </div>
      {usagers.data &&
        usagers?.data.length > 0 &&
        usagers?.data.map((usager) => (
          <UsagerListItem
            usager={usager}
            key={usager.id}
            isAction
            isFocus
            isUsagerDefault={isUsagerDefault || isUnknownUser}
          />
        ))}

      {isSearch &&
        usagers.data &&
        usagers?.data.length === 0 &&
        loading !== 'pending' && (
          <div className="ion-text-center">
            <IonLabel color="medium">
              <IonText className="no-result">Aucun résultat trouvé</IonText>
            </IonLabel>
          </div>
        )}

      <ConfirmModalUsager
        isOpen={modalSuccess}
        title="Merci d’indiquer le nombre d’inconnu "
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={() => closeModal()}
      />
    </StepThreeWrapper>
  )
}
const StepThreeWrapper = styled.div.attrs(() => ({
  className: useSelector((state: RootState) =>
    state.ficheActions.step_three.length > 0
      ? 'list-action show'
      : 'list-action',
  ),
}))`
  ion-searchbar {
    .searchbar-input {
      --padding-start: 5px;
    }
  }
`
export default StepThree
