import { useLazyQuery, useMutation } from "@apollo/client";
import { 
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonIcon,
  IonRow,
  IonText,
  IonTextarea,
  useIonViewDidEnter
 } from "@ionic/react"
import { getOrgId, getUserId } from 'src/utils/auth';
import { arrowBack, clipboardOutline } from "ionicons/icons"
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { CREATE_NOTE_MUTATION, LIST_TYPE_NOTES } from "src/graphql/usager-notes.graphql";
import { validateRequired } from "src/utils/validations";
import styled from "styled-components";
import { Selection } from "src/components";

const validators: ValidatorsType = {
  description: [validateRequired],
  noteType: [validateRequired],
}
const validatorsErros = {
  description: ['Le champ description est obligatoire'],
  noteType: ['Vous devez choisir un type'],
}

const CreateNote: React.FC = () => {
  const [loadTypeNotes, {
    loading: typeNoteLoading,
    data: typeNotesData,
  }] = useLazyQuery(LIST_TYPE_NOTES,  {
    variables: {
      filters: {
        organization: {id: {eq: getOrgId()}}
      }
    }
  });
  const { id: usagerId } = useParams<{ id: string }>()
  const [createNote, {loading, error} ] = useMutation(CREATE_NOTE_MUTATION)
  const [formValues, setFormValues] = useState<NoteForm>({
    noteType: '',
    description: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [isTouched, setIsTouched] = useState<NoteFormValidation>({})
  const [isValid, setIsValid] = useState<NoteFormValidation>({})

  const history = useHistory();

  const validate = (name: keyof NoteForm, value?: any): boolean => {
    if (!value) {
      // eslint-disable-next-line no-param-reassign
      value = formValues[name]
    }
    if (validators[name] && validators[name].length > 0) {
      // we need to validate
      let falsyIndex = -1;
      const isValidInput = validators[name].every((func, index) => {
        const shouldTrue = func(value);
        if (!shouldTrue) falsyIndex = index;
        return shouldTrue
      })
      if (isValidInput) {
        setIsValid((prev) => ({ ...prev, [name]: true }))
        setFormErrors((prev) => ({ ...prev, [name]:  undefined}))
        return true
      }
      setIsValid((prev) => ({ ...prev, [name]: false }))
      setFormErrors((prev) => ({ ...prev, [name]:  validatorsErros[name][falsyIndex]}))
      return false
    }
    setIsValid((prev) => ({ ...prev, [name]: true }))
    setFormErrors((prev) => ({ ...prev, [name]:  undefined}))
    return true
  }

  const create = async (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>): Promise<void> => {
    const allValid = Object.keys(validators).every((name) =>
      validate(name as keyof NoteForm),
    )
    setIsTouched({ noteType: true, description: true })
    if (!allValid) {
      return;
    }
    try {
      const result = await createNote({
        variables: {
          data: {
            usager: usagerId,
            author: getUserId(),
            noteType: formValues.noteType,
            description: formValues.description,
          },
        },
      })

      const { data } = result
      if (data?.createUsagerNote?.data?.id) {
       history.replace(`/main/usagers/view/${usagerId}/notes`)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error #1', e)
      throw e
    }
  }
  const handleChange = (key: keyof NoteForm) => (
    event: CustomEvent<InputChangeEventDetail>,
  ) => {
    const { value } = event.detail
    setFormValues((prevValues) => ({ ...prevValues, [key]: value }))
    validate(key, value);
  }
  useIonViewDidEnter(async () => {
    await loadTypeNotes();
  })

  return <CreateNoteWrapper>
     <IonRow>
      <IonCol>
        <IonButton className="inner-back-button" fill="clear" color="medium" onClick={ () =>history.push(`/main/usagers/view/${usagerId}/notes`)}>
          <IonIcon slot="start" icon={arrowBack} /> Retour
        </IonButton>
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        <div className="d-flex ion-align-items-center note-title">
          <IonIcon icon={clipboardOutline} color="primary" size="large" />
          <h3 color="primary"> Créer une nouvelle note </h3>
        </div>
      </IonCol>
    </IonRow>
    <IonRow className="ion-margin-bottom">
        <IonCol>
              <IonText className="label-select">Type de note</IonText>
              {typeNotesData &&  <Selection
                  name="resources"
                  onChange={handleChange('noteType')}
                  mode="md"
                  fill="outline"
                  value={formValues.noteType}
                  aria-label="Situation familiale "
                  placeholder="Choisir le type"
                  items={typeNotesData.noteTypes?.data?.map((i) => ({
                    id: i.id,
                    name: i.attributes?.name,
                  }))}
                />}
              {formErrors.noteType && (
                <IonText color="danger">{formErrors.noteType}</IonText>
              )}
        </IonCol>
      </IonRow>
      <IonRow>
          <IonCol>
                <IonText className="label-select">Description</IonText>
                <IonTextarea
                  autoGrow
                  placeholder="Rédiger votre note "
                  fill="solid"
                  errorText={formErrors.description ? formErrors.description : undefined}
                  onIonInput={handleChange('description')}
                 />
          </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
            <IonButton
                  disabled={!!error}
                  expand="block"
                  onClick={create}
                  color="primary"
                  size="large"
                >
                  Enregistrer
                </IonButton>
        </IonCol>
      </IonRow>
        
  </CreateNoteWrapper>
}

const CreateNoteWrapper = styled.div.attrs(props => ({
  className: 'create-note',
}))`
padding: 10px;
ion-textarea:not(.disabled) {
  opacity: 1;
  border: 1px solid #444141;
  border-radius: 8px;
  padding: 0 8px;
  transform: none !important; 
}
ion-select[disabled=true] {
  opacity: 1;
  --background: white;
  --border-width: 0;
}
ion-button.back {
  --padding-start: 0;
}
`
export default CreateNote
