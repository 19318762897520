import { useLazyQuery, useMutation } from '@apollo/client'
import {
  InputChangeEventDetail,
  IonButton,
  IonCol,
  IonIcon,
  IonRow,
  IonText,
  IonGrid,
  IonTextarea,
  useIonLoading,
  useIonViewDidEnter,
} from '@ionic/react'
import { arrowBack, clipboardOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  FETCH_ONE_HEALTH_NOTE,
  UPDATE_HEALTH_NOTE_MUTATION,
} from 'src/graphql/usager-health-notes.graphql'
import { validateRequired } from 'src/utils/validations'
import styled from 'styled-components'

const validators: ValidatorsType = {
  description: [validateRequired],
  noteType: [validateRequired],
}
const validatorsErros = {
  description: ['Le champ description est obligatoire'],
  noteType: ['Vous devez choisir un type'],
}

const UpdateHealth: React.FC = () => {
  const { id: usagerId, healthId } = useParams<{
    id: string
    healthId: string
  }>()

  const [present, dismiss] = useIonLoading()
  const [
    getHealtNotes,
    { loading: loadingNote, data: dataHealthNote },
  ] = useLazyQuery(FETCH_ONE_HEALTH_NOTE, {
    notifyOnNetworkStatusChange: true,
    variables: { id: healthId },
  })

  useIonViewDidEnter(async () => {
    await getHealtNotes()
  })

  const [updateNote, { loading, error }] = useMutation(
    UPDATE_HEALTH_NOTE_MUTATION,
  )

  useEffect(() => {
    if (loadingNote) {
      present()
    } else {
      dismiss()
    }
    return () => {
      dismiss()
    }
  }, [dismiss, loadingNote, present])
  const [formValues, setFormValues] = useState<NoteForm>({
    noteType: '',
    description:
      dataHealthNote?.usagerHealthNote?.data?.attributes?.description || '',
  })

  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [isTouched, setIsTouched] = useState<NoteFormValidation>({})
  const [isValid, setIsValid] = useState<NoteFormValidation>({})

  const history = useHistory()

  const validate = (name: keyof NoteForm, value?: any): boolean => {
    if (!value) {
      // eslint-disable-next-line no-param-reassign
      value = formValues[name]
    }
    if (validators[name] && validators[name].length > 0) {
      // we need to validate
      let falsyIndex = -1
      const isValidInput = validators[name].every((func, index) => {
        const shouldTrue = func(value)
        if (!shouldTrue) falsyIndex = index
        return shouldTrue
      })
      if (isValidInput) {
        setIsValid((prev) => ({ ...prev, [name]: true }))
        setFormErrors((prev) => ({ ...prev, [name]: undefined }))
        return true
      }
      setIsValid((prev) => ({ ...prev, [name]: false }))
      setFormErrors((prev) => ({
        ...prev,
        [name]: validatorsErros[name][falsyIndex],
      }))
      return false
    }
    setIsValid((prev) => ({ ...prev, [name]: true }))
    setFormErrors((prev) => ({ ...prev, [name]: undefined }))
    return true
  }

  const update = async (
    event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>,
  ): Promise<void> => {
    event.preventDefault()
    const allValid = Object.keys(validators).every((name) =>
      validate(name as keyof NoteForm),
    )
    setIsTouched({ description: true })
    if (!allValid) {
      return
    }

    try {
      const result = await updateNote({
        variables: {
          id: healthId,
          data: {
            usager: usagerId,
            description: formValues.description,
          },
        },
      })

      const { data } = result
      if (data?.updateUsagerHealthNote?.data?.id) {
        history.replace(`/main/usagers/view/${usagerId}/health`)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error #1', e)
      throw e
    }
  }
  const handleChange = (key: keyof NoteForm) => (
    event: CustomEvent<InputChangeEventDetail>,
  ) => {
    const { value } = event.detail
    setFormValues((prevValues) => ({ ...prevValues, [key]: value }))
    validate(key, value)
  }

  return (
    <UpdateHealthWrapper>
      <IonGrid className="ion-no-padding update-health">
        <IonRow>
          <IonCol className="ion-no-padding">
            <IonButton
              className="inner-back-button"
              fill="clear"
              color="medium"
              onClick={() =>
                history.push(`/main/usagers/view/${usagerId}/health`)
              }
            >
              <IonIcon slot="start" icon={arrowBack} /> Retour
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-no-padding">
            <IonText className="note-title">
              <h3 className="h3-title"> Mise à jour de la note santé </h3>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className="update-area">
          <IonCol>
            <IonText className="label-select">Description</IonText>
            <IonTextarea
              autoGrow
              placeholder="Rédiger votre note "
              fill="solid"
              errorText={
                formErrors.description ? formErrors.description : undefined
              }
              onIonInput={handleChange('description')}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              disabled={!!error}
              expand="block"
              onClick={update}
              color="primary"
              size="large"
            >
              Sauvegarder
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </UpdateHealthWrapper>
  )
}

const UpdateHealthWrapper = styled('div')`
  padding: 10px;
  ion-textarea:not(.disabled) {
    opacity: 1;
    border: 1px solid #444141;
    border-radius: 8px;
    padding: 0 8px;
    transform: none !important;
  }
  ion-select[disabled='true'] {
    opacity: 1;
    --background: white;
    --border-width: 0;
  }
  ion-button.back {
    --padding-start: 0;
  }
`
export default UpdateHealth
