import React from 'react';
import { IonButton,  IonCol,  IonContent,  IonGrid, IonRow, IonText} from '@ionic/react';
import styled from 'styled-components';
import ValidatedIcon from 'src/components/ValidatedIcon/ValidatedIcon';
import { informationCircleOutline } from 'ionicons/icons';



const ExportModalNoData: React.FC<{onSubmit: (e: any) => void}> = ({
  onSubmit
}) => {
  
  return (
      <ExportModalNoDataWrapper>
     
          <IonGrid className="ion-padding">
          <IonRow>
            <IonCol className="ion-text-center">
              <ValidatedIcon 
              warningColor
              icon={informationCircleOutline}
              height={50} />
              <IonText><h4>Aucune donnée</h4></IonText>
             <IonText><p>L’export que vous avez paramétré n'a pas de données, veuillez réessayer</p></IonText>
              </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton color="primary" expand="block" fill="outline" size="large" onClick={onSubmit}>
                Précedent 
              </IonButton>
            </IonCol>
          </IonRow>
         
        </IonGrid>
      </ExportModalNoDataWrapper>
)};
const ExportModalNoDataWrapper = styled(IonContent) `

`
export default ExportModalNoData;