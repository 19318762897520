/* eslint-disable no-console */
/* eslint-disable camelcase */
import { useMutation } from '@apollo/client'
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonModal,
  IonRow,
  IonText,
  useIonLoading,
  useIonToast,
} from '@ionic/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UsagerListItem from 'src/components/UsagerListItem/UsagerListItem'
import { CREATE_ACTION_MUTATION } from 'src/graphql/actions-types.graphql'
import { UsagerEntity } from 'src/models/gql/graphql'
import {
  removeStepThree,
  setCurrentStep,
  switchNbUnkown,
} from 'src/stores/slices/ficherActionsSlices'
import { RootState } from 'src/stores/store'
import { getOrgId, getUserId } from 'src/utils/auth'

import { formatDateInsertion } from 'src/utils/formatting'

import './ConfirmModal.css'

export interface IConfirmModalProps {
  isOpen?: boolean
  backdropDismiss?: boolean
}
const ModalUsagerSelected: React.FC<IConfirmModalProps> = ({
  isOpen,
  backdropDismiss,
}) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const [presentLoading, dismissLoading] = useIonLoading()
  const [, setShowModal] = useState(isOpen)
  const dispatch = useDispatch()
  const [
    createAction,
    { loading: createLoading, error: errorCreate },
  ] = useMutation(CREATE_ACTION_MUTATION)
  const {
    step_three,
    currentStep,
    nbUnkown,
    nbUnknownUsagers,
    step_one,
    step_two,
  } = useSelector((state: RootState) => state.ficheActions)

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen, setShowModal])

  useEffect(() => {
    if (createLoading) {
      presentLoading()
    } else {
      dismissLoading()
    }

    return () => {
      dismissLoading()
    }
  }, [createLoading, dismissLoading, presentLoading])

  const [present] = useIonToast()

  const presentToast = useCallback(() => {
    present({
      message:
        'Désolé, vous avez sélectionné un utilisateur par défaut lors de la création du fiche.',
      duration: 4000,
      position: 'bottom',
    })
  }, [present])

  const presentToastError = useCallback(() => {
    present({
      message:
        "Une erreur s'est produite, veuillez contacter l'admin ou réessayer plus tard",
      duration: 4000,
      position: 'bottom',
    })
  }, [present])

  const handleDeleteItem = (
    id: number | string,
    usagers: UsagerEntity,
  ): void => {
    if (!backdropDismiss) {
      dispatch(removeStepThree({ id, usager: usagers }))
      if (nbUnknownUsagers > 0) {
        dispatch(switchNbUnkown())
      }
    } else {
      presentToast()
    }
  }
  const getUsagers = (): string[] => {
    return step_three.map((res: UsagerEntity) => {
      return res?.id
    }) as []
  }
  const createFiches = async (): Promise<void> => {
    try {
      const actions = step_two.map((res) =>
        createAction({
          variables: {
            data: {
              author: getUserId(),
              organization: getOrgId(),
              usagers: step_three.length > 0 ? getUsagers() : [],
              actionDate: formatDateInsertion(new Date().toISOString()),
              nbUnknownUsagers,
              actionType: res.id.toString(),
              quantity: res?.quantity,
              signalementDescription: step_one.description,
              orientationMadeBy: step_one.orientation,
              service: step_one.service,
            },
          },
        }),
      )
      await Promise.all(actions)
      dispatch(setCurrentStep(currentStep + 1))
    } catch (e) {
      presentToastError()
      console.error('error #1', e)
      throw e
    }
  }

  return (
    <IonModal
      ref={modal}
      isOpen
      initialBreakpoint={0.1}
      breakpoints={[0.1, 0.5, 0.9]}
      backdropDismiss={false}
      backdropBreakpoint={0.5}
      className="swipe-modal modal-inconnu"
    >
      <IonContent className="ion-padding">
        <IonRow>
          <IonCol>
            <div className="select-action">
              {step_three.length > 0 ? (
                <IonText>
                  {step_three.length}{' '}
                  {step_three.length <= 1
                    ? 'usager sélectionné(e)'
                    : 'usagers sélectionné·e·s'}
                </IonText>
              ) : (
                <IonText>
                  {' '}
                  Vous avez sélectionné {nbUnkown}{' '}
                  {nbUnkown <= 1
                    ? 'usager comme inconnu'
                    : 'usagers comme inconnus'}
                </IonText>
              )}

              {nbUnkown > 0 && step_three.length === 0 && (
                <IonButton
                  color="primary"
                  expand="block"
                  size="small"
                  className="btn-small-white"
                  onClick={() => {
                    createFiches()
                  }}
                >
                  Continuer
                </IonButton>
              )}
              {step_three.length > 0 && (
                <IonButton
                  color="primary"
                  expand="block"
                  size="small"
                  className="btn-small-white"
                  onClick={() => {
                    createFiches()
                  }}
                >
                  Valider
                </IonButton>
              )}
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="category-selected-usager-row">
          <IonCol className="ion-no-padding">
            {step_three.map((usager) => (
              <div className="selected-usager">
                <UsagerListItem
                  usager={usager}
                  key={`${usager.id}selected`}
                  isSelected
                  isAction
                />
                <IonIcon
                  className="delete"
                  key={`${usager.id}icon`}
                  onClick={() => handleDeleteItem(usager?.id || '', usager)}
                />
              </div>
            ))}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonModal>
  )
}

export default ModalUsagerSelected
