/* eslint-disable no-underscore-dangle */
import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  split
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { StorageUtils } from "src/utils";
import { setContext } from '@apollo/client/link/context';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
});
const authLink = setContext((_, {headers}) => {
  const token = StorageUtils.get('authToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});



export default class ApolloService {

  private static _client: ApolloClient<unknown> | undefined;

  public static getInstance(): ApolloClient<unknown> {
    if (ApolloService._client) {
      return ApolloService._client;
    }
    ApolloService._client = new ApolloClient<unknown>({
      cache: new InMemoryCache({
        addTypename: false
      }),
      link:  authLink.concat(httpLink),
      defaultOptions,
    });
    return ApolloService._client;
  }

  public static reinitialize(): ApolloClient<unknown> {
    // eslint-disable-next-line no-underscore-dangle
    ApolloService._client = undefined;
    return ApolloService.getInstance();
  }
}

