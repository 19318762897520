/* eslint-disable no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  InputChangeEventDetail,
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  useIonLoading,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ModalLayout } from 'src/components'
import {
  CREATE_TRANSMISSION_NOTE,
  FETCH_ONE_TRANSMISSION,
  UPDATE_TRANSMISSION_NOTE,
} from 'src/graphql/transmissions-types.graphql'
import useToast from 'src/hooks/toast'
import { getOrgId, getUserId } from 'src/utils/auth'

type FormValues = {
  description: string
  isPriority: boolean
}

const initialFormValues: FormValues = {
  description: '',
  isPriority: false,
}

const CrTransmissions: React.FC = () => {
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues)
  const [isSubmitted, setSubmitted] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [presentLoading, dismissLoading] = useIonLoading()
  const [createAction, { loading: createLoading }] = useMutation(
    CREATE_TRANSMISSION_NOTE,
  )

  const [updateAction, { loading: updateLoading }] = useMutation(
    UPDATE_TRANSMISSION_NOTE,
  )

  const [getInitData, { loading }] = useLazyQuery(FETCH_ONE_TRANSMISSION)

  const toaster = useToast({
    text: 'Modifications  transmissions de notes avec succès !',
    position: 'bottom',
  })

  const creationTransmision = async (): Promise<void> => {
    setSubmitted(true)
    if (formValues.description)
      try {
        const result = await createAction({
          variables: {
            data: {
              author: getUserId(),
              organization: getOrgId(),
              description: formValues.description,
              isPriority: formValues.isPriority,
              isNew: true,
            },
          },
        })

        if (result.data?.createTransmissionNote?.data?.id) {
          setSubmitted(false)
          setFormValues({
            isPriority: false,
            description: '',
          })
          history.push('/create/transmissions/success')
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error #1', e)
        throw e
      }
  }

  const updateTransmission = async (): Promise<void> => {
    setSubmitted(true)
    if (formValues.description)
      try {
        const result = await updateAction({
          variables: {
            id,
            data: {
              author: getUserId(),
              organization: getOrgId(),
              description: formValues.description,
              isPriority: formValues.isPriority,
            },
          },
        })

        if (result.data?.updateTransmissionNote?.data?.id) {
          setSubmitted(false)
          history.push('/main/transmissions')
          toaster()
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error #1', e)
        throw e
      }
  }

  useEffect(() => {
    if (id) {
      getInitData({
        variables: { id },
      }).then((res) => {
        if (res.data?.transmissionNote?.data) {
          setFormValues({
            isPriority:
              res.data?.transmissionNote?.data?.attributes?.isPriority || false,
            description:
              res.data?.transmissionNote?.data?.attributes?.description || '',
          })
        }
      })
    }
  }, [getInitData, id])

  useEffect(() => {
    if (createLoading || updateLoading || loading) {
      presentLoading()
    } else {
      dismissLoading()
    }

    return () => {
      dismissLoading()
    }
  }, [createLoading, dismissLoading, loading, presentLoading, updateLoading])

  const handleDescriptionChange = (
    ev: CustomEvent<InputChangeEventDetail>,
  ): void => {
    const { value } = ev.target as HTMLInputElement
    setFormValues((prevValues) => ({
      ...prevValues,
      description: value,
    }))
  }

  const handleBack = (): void => {
    history.push('/main/transmissions')
  }

  return (
    <IonPage>
      <ModalLayout
        title={
          !id ? 'Créer une note transmission' : 'Modifier la note transmission'
        }
        classNameColor="header-green"
        onBack={handleBack}
      >
        <IonGrid className="ion-margin-top">
          <IonRow>
            <IonCol size="12">
              <IonLabel position="stacked" className="label-select">
                Description du signalement
              </IonLabel>
              <IonTextarea
                autoGrow
                className="textarea-note"
                placeholder="Rédiger votre transmission"
                fill="solid"
                name="message"
                value={formValues.description}
                onIonInput={(e) => handleDescriptionChange(e)}
                required
              />
              {isSubmitted && !formValues.description && (
                <IonText color="danger">Le champ est obligatoire</IonText>
              )}
            </IonCol>

            <IonCol size="12">
              <IonItem>
                <IonLabel className="label-check">Prioritaire</IonLabel>
                <IonCheckbox
                  disabled={!!id}
                  className="priority-check"
                  slot="start"
                  checked={formValues.isPriority}
                  onIonChange={() =>
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      isPriority: !formValues.isPriority,
                    }))
                  }
                  name="isPriority"
                />
              </IonItem>
            </IonCol>

            <IonCol size="12" className="create-note">
              <div className="create-by">
                <div className="create-by-image">
                  <IonImg
                    src="/assets/images/exclamation.svg"
                    style={{ width: 24, height: 24 }}
                  />
                </div>
                <div className="create-by-desc">
                  <p>
                    Si la case prioritaire est cochée, cette note sera envoyé en
                    Notification sur le téléphone de tous les utilsateurs de
                    l'appli Paxtel de votre structure.
                  </p>
                </div>
              </div>
            </IonCol>

            <IonCol size="12">
              <IonButton
                color="primary next"
                expand="block"
                size="large"
                onClick={!id ? creationTransmision : updateTransmission}
              >
                {!id
                  ? 'Envoyer la note transmission '
                  : 'Modifier la note transmission'}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ModalLayout>
    </IonPage>
  )
}

export default CrTransmissions
