/* eslint-disable react/no-danger */
import { IonCard, IonCardContent, IonCol, IonImg, IonText } from '@ionic/react'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import store, { RootState } from 'src/stores/store'
import { fetchSelectCategoriesActions } from 'src/stores/thunks/fiche-actions-thunks'
import { getOrgId } from 'src/utils/auth'
import './FicheActionListItem.css'

export interface IActionListFicheItemProps {
  name?: string
  icon?: string
  idCategory: string
}

const FicheActionListItem: FC<IActionListFicheItemProps> = ({
  name,
  icon,
  idCategory,
}) => {
  const { loading } = useSelector((state: RootState) => state.ficheActions)
  const [idSelected, setIdSelected] = useState('`')
  const select = (): void => {
    setIdSelected(idCategory)
    store.dispatch(
      fetchSelectCategoriesActions({
        filters: {
          and: [
            {
              organization: { id: { eq: getOrgId() } },
            },

            {
              category: {
                id: { eq: idCategory.toString() },
              },
            },
          ],
        },
        pagination: { page: 1, pageSize: 52 },
        sort: ['updatedAt:DESC'],
      }),
    )
  }

  return (
    <IonCol size="6">
      <IonCard onClick={select}>
        <IonCardContent
          className={`fiche-list ${
            idSelected.toString() === idCategory.toString() ? 'selected' : ''
          }`}
        >
          <IonText> {name} </IonText>
          {/* icon && (
            <div
              className="card-icon"
              dangerouslySetInnerHTML={{ __html: icon }}
            />
          ) */}
          {/* <IonImg src="/assets/images/admin.svg" alt="Admin" /> */}

          {icon ? (
            <IonImg
              src={icon}
              alt="Admin"
              style={{ width: 40, height: 40 }}
              className="filter-orange"
            />
          ) : (
            <IonImg
              src="/assets/images/admin.svg"
              alt="Admin"
              style={{ width: 40, height: 40 }}
            />
          )}
        </IonCardContent>
      </IonCard>
    </IonCol>
  )
}

export default FicheActionListItem
