import React, { useEffect, useState } from 'react';
import { IonButton,  IonCol,  IonGrid,  IonModal,  IonRow,  IonText } from '@ionic/react';
import styled from 'styled-components';

export interface IComponentModalProps {
  isOpen: boolean
  title?: string;
  onCancel: () => void;
  children: React.ReactNode
}

const ComponentModal: React.FC<IComponentModalProps> = ({
  isOpen,
  title,
  onCancel,
  children
 }) => {
    const [showModal, setShowModal] = useState(isOpen);
    useEffect(() => {
      setShowModal(isOpen)
    }, [isOpen, setShowModal])
  const handleCancel = () => {
    setShowModal(false);
    onCancel();
  }
  return (
      <ComponentModalWrapper
      mode="ios"
      isOpen={showModal}
      initialBreakpoint={0.90}
      onDidDismiss={() => handleCancel()}
      breakpoints={[0, 0.90, 1]}>
              {children}
      </ComponentModalWrapper>
)};
const ComponentModalWrapper = styled(IonModal) `
`
export default ComponentModal;